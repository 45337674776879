import { FunctionComponent, useCallback, useEffect, useState } from "react";
import MyCalendar from "../components/calendar/MyCalendar";
import { Box, Menu, MenuItem } from "@mui/material";
import { useFetchAdministratedGarages } from "../query/garage/GarageQuery";
import { type View, Views } from "react-big-calendar";
import { CalendarView } from "../helpers/Enums";
import { DEFAULT_CALENDAR_VIEW } from "../helpers/Constants";
import { getCalendarPeriod } from "../helpers/DateUtils";
import { useFetchEventsList } from "../query/event/EventQuery";
import { useDispatch } from "react-redux";
import { createCookie, getMenuItemStyles, readCookie, SETTINGS_CALENDAR } from "../helpers/Tools";
import { useTheme } from "@emotion/react";

interface AgendaProps {
  garageId: string;
}

//xs, sm, md, lg, and xl
const Agenda: FunctionComponent<AgendaProps> = ({ garageId }) => {
  const [periodDate, setPeriodDate] = useState<Date>(new Date());

  const getDefaultView = (): View => {
    if (garage?.teamManagementActive && garage?.defaultView && garage.employees && garage.employees?.length > 0)
    {
      switch (garage.defaultView)
      {
        case CalendarView.DAY:
          return Views.DAY;
        case CalendarView.MONTH:
          return Views.MONTH;
        case CalendarView.WEEK:
          return Views.WEEK;
        case CalendarView.EMPLOYEES:
          return Views.WORK_WEEK;
        case CalendarView.FOUR_DAYS:
          return Views.AGENDA;
        default:
          return DEFAULT_CALENDAR_VIEW;
      }
    }
    return DEFAULT_CALENDAR_VIEW;
  };
  const dispatch = useDispatch();
  const { data: garage } = useFetchAdministratedGarages(garageId);
  const [view, setView] = useState<View>(getDefaultView() ?? DEFAULT_CALENDAR_VIEW);
  const { startDate, endDate } = getCalendarPeriod(periodDate.getTime(), view, garage?.schedules);
  const { data: events, refetch: refetchEvents } = useFetchEventsList({
    view,
    dispatch,
    garage: garage!,
    schedules: garage?.schedules || [],
    startPeriod: startDate,
    endPeriod: endDate,
  });
  const theme = useTheme();
  const handleOnView = useCallback((view: View) => {
    setView(view);
  }, []);




  return (
    // 72px height of the toolbar
    garage && events ? <Box  sx={{
      pt: {
        xs: '48px', // Pour les écrans plus petits que 900px
        md: '72px', // Pour les écrans à partir de 900px
      },
      height: {
        xs: 'calc(var(--fullHeight) - 48px)', // Pour les écrans plus petits que 900px
        md: 'calc(var(--fullHeight) - 72px)', // Pour les écrans à partir de 900px
      },
    }}>
      <MyCalendar garage={garage} handleOnView={handleOnView} view={view} events={events} refetchEvents={refetchEvents} setPeriodDate={setPeriodDate} periodDate={periodDate} startDate={startDate} endDate={endDate} />
    </Box> : <></>
  );
};

export default Agenda;

