import type { DayOfWeek, Garage, Schedule } from '@movalib/movalib-commons';
import { compareAsc, startOfDay } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { fr } from 'date-fns/locale';
import type { View } from 'react-big-calendar';
import { EVENTS_MEMO_OFFSET } from './Constants';
import { PeriodType } from './Enums';
import { getEndOf, getStartOf } from './Tools';

export function compareDates(date1: Date, date2: Date): number {
  const d1 = startOfDay(date1);
  const d2 = startOfDay(date2);

  return compareAsc(d1, d2);
}

// Tableau de correspondance entre les codes de pays et les fuseaux horaires
export const countryTimeZones: { [key: string]: string } = {
  FR: 'Europe/Paris',
  // Ajoutez d'autres correspondances de codes de pays et de fuseaux horaires ici
};

export enum DateFormatTypes {
  TIME_ONLY = 'HH:mm',
  SHORT_FORMAT_DATE = 'dd/MM/yyyy',
  LONG_FORMAT_DATETIME = 'dd/MM/yyyy HH:mm:ss',
  LONG_FORMAT_DATETIME_LITERAL = "EEEE dd MMMM yyyy 'à' HH:mm",
}

export const findEarliestScheduleTime = (schedules: Schedule[], timeType: 'startTime' | 'endTime') => {
  let earliestTime = '23:59:59'; // Heure initiale tardive pour la comparaison
  let latestTime = '00:00:00'; // Heure initiale matinale pour la comparaison

  schedules.forEach((schedule) => {
    schedule.intervals.forEach((interval) => {
      const time = interval[timeType];
      if (!time) {
        return;
      }

      if (time > latestTime && timeType === 'endTime') {
        if (time && time > latestTime) {
          latestTime = String(time);
        }
      } else {
        if (time < earliestTime) {
          earliestTime = String(time);
        }
      }
    });
  });

  if (timeType === 'endTime') {
    return latestTime;
  }
  return earliestTime;
};

export const findDayScheduleTime = (schedules: Schedule[], dayOfWeek: DayOfWeek, timeType: 'startTime' | 'endTime') => {
  const schedule = schedules.find((s) => s.dayOfWeek === dayOfWeek);

  if (schedule) {
    return findEarliestScheduleTime([schedule], timeType);
  }

  return '';
};

export const toLocaleDate = (date: Date, timeZone: string): Date => {
  let zonedDate = date;

  if (date) {

    if (!timeZone) {
      throw new Error('Code de pays non pris en charge');
    }

    // Convertir la date UTC en date du fuseau horaire local
    zonedDate = utcToZonedTime(date, timeZone);
  }

  return zonedDate;
};

export const formatDateByTimezone = (
  date: Date | undefined,
  timeZone: string,
  formatType: DateFormatTypes,
): string => {
  if (date) {
    // Convertir la date UTC en date du fuseau horaire local
    const zonedDate = toLocaleDate(date, timeZone);

    // Formater la date
    switch (formatType) {
      case DateFormatTypes.SHORT_FORMAT_DATE:
      case DateFormatTypes.LONG_FORMAT_DATETIME:
        return format(zonedDate, formatType, { timeZone, locale: fr });

      case DateFormatTypes.LONG_FORMAT_DATETIME_LITERAL:
        return getLongFormattedDateTime(zonedDate, timeZone, fr);
    }

    return format(zonedDate, formatType, { timeZone, locale: fr });
  }

  return '';
};

export function getLongFormattedDateTime(date: Date | undefined, timeZone: string, locale: Locale) {
  if (date) {
    const day = capitalizeFirstLetter(format(date, 'eeee', { timeZone, locale: locale }));
    const month = capitalizeFirstLetter(format(date, 'MMMM', { timeZone, locale: locale }));
    const hours = format(date, 'HH', { timeZone, locale: locale });
    const minutes = format(date, 'mm', { timeZone, locale: locale });
    const dayOfMonth = format(date, 'dd', { timeZone, locale });

    return `${day} ${dayOfMonth} ${month} à ${hours}:${minutes}`;
  }
  return '';
}

export function capitalizeFirstLetter(str: string): string {
  if (str.length === 0) {
    return str;
  }

  const firstChar = str.charAt(0).toUpperCase();
  const restOfString = str.slice(1);

  return firstChar + restOfString;
}

const viewPeriodTypeMatcher: Record<View, PeriodType> = {
  month: PeriodType.MONTH,
  day: PeriodType.DAY,
  week: PeriodType.WEEK,
  work_week: PeriodType.WEEK,
  agenda: PeriodType.FOUR_DAY,
} as const;

export function getCalendarPeriod(dateTime: number, view: View, schedules?: Garage['schedules']) {
  if (!schedules || !schedules.length) {
    return {
      startDate: getStartOf(new Date(dateTime), viewPeriodTypeMatcher[view]),
      endDate: getEndOf(new Date(dateTime), viewPeriodTypeMatcher[view]),
    };
  }

  // On récupère les horaires limites de la semaine (début et fin)
  const [startHour, startMin] = findEarliestScheduleTime(schedules, 'startTime').split(':').map(Number);
  const [endHour, endMin] = findEarliestScheduleTime(schedules, 'endTime').split(':').map(Number);

  let startDate: Date;
  let endDate: Date;
 
  const selectRangeDateByViews = (periodType: PeriodType) =>  {
    
    const startDate = getStartOf(new Date(dateTime), periodType);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6 + EVENTS_MEMO_OFFSET);
    return {startDate, endDate};
  }

  switch (viewPeriodTypeMatcher[view]) {
    case PeriodType.DAY:
      // for the day view we use the same number of days as the week view (EVENTS_MEMO_OFFSET + 6), only the start date differs
      ({startDate, endDate} = selectRangeDateByViews(PeriodType.DAY));
      break;
    case PeriodType.WEEK:
      // In week view, we have to setup from monday (startDate) to saturday (startDate + 6 days)
      // We also have to add the EVENTS_MEMO_OFFSET to have all infos about the event
    ({startDate, endDate} = selectRangeDateByViews(PeriodType.WEEK));
      break;
      case PeriodType.FOUR_DAY:
    ({startDate, endDate} = selectRangeDateByViews(PeriodType.FOUR_DAY));
    break;
    case PeriodType.MONTH:
      startDate = getStartOf(new Date(dateTime), PeriodType.MONTH);
      // In monthly view, we have to setup from the first day of the month to the last day of the month
      endDate = getEndOf(new Date(dateTime), PeriodType.MONTH);
      break;
  }

  startDate.setHours(startHour, startMin);
  endDate.setHours(endHour, endMin);
  return { startDate, endDate };
}
