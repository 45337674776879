import { type CSSProperties, memo, useEffect, useState, type FunctionComponent } from 'react';
import pricingData from './pricing.json';
import PricingPackage from './PricingPackage';
import Box from '@mui/material/Box';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import LegendOffers from './LegendOffers';
import { darken, Grid, Typography, Alert, TextField, Tabs, FormControl, Input, InputAdornment } from '@mui/material';
import theme from '../../../theme';
import { StyledTab } from '../../../helpers/Styled';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import type { VisibilityOffersData } from './offers/VisibilityOffers';
import type { MovaPackageForm } from '../SignUp';

export const styleHeaders: CSSProperties = {
  // maxHeight: '150px',
  minHeight: '120px',
};

export const styleClassique: CSSProperties = {
  minHeight: '28px',
  flexGrow: 1,
  flex: 1,
};

export const styleReparateur: CSSProperties = {
  minHeight: '32px',
  flex: 1,
};

export const stylePLV: CSSProperties = {
  minHeight: '32px',
  flex: 1,
};

export const styleFormation: CSSProperties = {
  minHeight: '32px',
  maxHeight: '32px',
  flex: 1,
};
interface PricingToolsProps {
  amortization?: boolean;
  withIcon?: boolean;
  selectable?: boolean;
  workforce?: number;
  handleOfferChange?: (offer: any) => void;
  formData?: MovaPackageForm;
  onlyOne?: boolean;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

enum SubscriptionTabs {
  ALLOWANCE = 0,
  GAIN = 1,
  LAUNCH_OFFER = 2,
}

const CustomTabPanel = memo((props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3, px: 2 }}>{children}</Box>}
    </div>
  );
});

const areEqual = (prevProps: PricingToolsProps, nextProps: PricingToolsProps) => {
  // Comparaison des valeurs de formData (ici, deep égalité)
  const isFormDataEqual = JSON.stringify(prevProps.formData) === JSON.stringify(nextProps.formData);
  // On retourne true si les props n'ont pas changé (donc pas de re-render)
  return prevProps.withIcon === nextProps.withIcon &&
    prevProps.selectable === nextProps.selectable &&
    prevProps.amortization === nextProps.amortization &&
    prevProps.workforce === nextProps.workforce &&
    isFormDataEqual; // Si formData n'a pas changé, ne pas re-render
};

const PricingTools: FunctionComponent<PricingToolsProps> = ({
  withIcon = true,
  selectable = true,
  amortization = true,
  onlyOne = false,
  workforce = 0,
  handleOfferChange,
  formData,
}: PricingToolsProps) => {
  const [data, setData] = useState<any>(null);
  const [currentSelectedPackage, setCurrentSelectedPackage] = useState<number>();
  const [tabValue, setTabValue] = useState<SubscriptionTabs>(SubscriptionTabs.ALLOWANCE);
  const [currentOffer, setCurrentOffer] = useState<any>();
  const [formValues, setFormValues] = useState({
    dailyInvoices: 6,
    averageRate: 4,
    averageRateRatio: 75,
    averageEstimate: 2,
    averageEstimateRatio: 220,
    averageCostAppointment: 1,
    averageCostAppointmentRatio: 220,
  });
  const formFormik = useFormik({
    initialValues: {
      repaireSupp: formData?.additionalRepaire?.quantity ?? 0,
      partialRepaireSupp: formData?.additionalPartialRepaire?.quantity ?? 0,
      formationSupp: formData?.additionalFormation?.quantity ?? 0,
      formationFree: formData?.additionalFormation?.free ?? false,
      plvFree: formData?.plv?.free ?? false,
      plv0: null,
      plv1: null,
      plv2: null,
      webPage: formData?.webPage?.include ?? false,
      webcam: formData?.webcam?.include ?? false,
    },
    onSubmit: () => { },
    validationSchema: Yup.object().shape({
      repaireSupp: Yup.number().optional().min(0),
      formationSupp: Yup.number().optional().min(0),
      webPage: Yup.boolean().optional(),
      webcam: Yup.boolean().optional(),
      plv: Yup.number().optional(),
    }),
    enableReinitialize: true,
  });

  const selectedPackage = (id: number) => {
    setCurrentSelectedPackage(id);
  };

  useEffect(() => {
    // Charger les données JSON à partir de l'import
    setData(pricingData);
    const dataPlv = (pricingData as any).options?.visibilite?.filter((item: VisibilityOffersData) => item.id === 10)[0].offers;
    formFormik.setFieldValue('plv0', formData?.movaPackage?.id === 0 && formData?.plv?.id ? formData?.plv.id : dataPlv?.filter((offre: VisibilityOffersData["offers"][0]) => offre.id === 0)[0].defaultValueId ?? null);
    formFormik.setFieldValue('plv1', formData?.movaPackage?.id === 1 && formData?.plv?.id ? formData?.plv.id : dataPlv?.filter((offre: VisibilityOffersData["offers"][0]) => offre.id === 1)[0].defaultValueId ?? null);
    formFormik.setFieldValue('plv2', formData?.movaPackage?.id === 2 && formData?.plv?.id ? formData?.plv.id : dataPlv?.filter((offre: VisibilityOffersData["offers"][0]) => offre.id === 2)[0].defaultValueId ?? null);
    setCurrentSelectedPackage(formData?.movaPackage?.id ?? 1);

  }, []);

  const handleChangeOffer = (offer: any) => {
    setCurrentOffer(offer);
    if (handleOfferChange)
    {
      handleOfferChange(offer);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: Number(e.target.value),
    }));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getRdvRatioCost = (): number => {
    if (currentOffer?.totalPrice)
    {
      return currentOffer.totalPrice / (formValues.dailyInvoices * 20);
    }
    return 0;
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 1, height: '100%' }}>

        <LegendOffers withIcon={withIcon} options={data?.options} currentPackageSelected={currentOffer?.movaPackage} />
        {(onlyOne ? data?.offers?.filter((offer: any) => offer.id === currentSelectedPackage) : data?.offers)?.map((offer: any) => (
          <PricingPackage
            formFormik={formFormik}
            key={offer.id}
            movaPackage={offer}
            options={data?.options}
            selectable={selectable}
            workforce={workforce}
            onlyView={onlyOne}
            plv={(offer.id === currentSelectedPackage) ? formData?.plv?.price ?? 0 : 0}
            handleOfferChange={handleChangeOffer}
            setPackageSelected={selectedPackage}
            packageSelected={currentSelectedPackage}
          />
        ))}

      </Box>
      {amortization && <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', pt: 7 }}>
        <Box sx={{ minWidth: '40%', maxWidth: '40%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ display: tabValue === 1 ? 'flex' : 'none' }}>
            <Alert
              sx={{ borderRadius: 20, fontSize: '1.0rem', textAlign: 'center' }}
              icon={<span />}
              className='styled-alert'
            >
              Soit, fourchette basse, une <b>croissance de CA</b> estimée à&nbsp;
              <b>
                {formValues.averageRate * formValues.averageRateRatio +
                  formValues.averageCostAppointment * formValues.averageCostAppointmentRatio +
                  formValues.averageEstimate * formValues.averageEstimateRatio}
                €
              </b>
              &nbsp; grâce à Movalib
            </Alert>
          </Box>

          <Box sx={{ display: tabValue === 0 ? 'flex' : 'none' }}>
            <Alert sx={{ borderRadius: 20 }} icon={<span />} className='styled-alert'>
              * Coût de revient estimé pour <b>{formValues.dailyInvoices * 20}</b> factures par mois.
            </Alert>
          </Box>
        </Box>
        <Box sx={{ flexDirection: 'column', width: '70%' }} style={flexCenter}>
          <TextField
            id='daily-appointments'
            label='FACTURES JOUR'
            value={formValues.dailyInvoices}
            type='number'
            required
            size='small'
            onChange={(e) => {
              setTabValue(SubscriptionTabs.ALLOWANCE);
              handleInputChange(e, 'dailyInvoices');
            }}
            helperText='Nombre de factures / jour'
            sx={{ width: '90%', mt: 2.5 }}
            InputProps={{ inputProps: { min: 1 } }}
          />

          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant='fullWidth'
            sx={{ width: '100%' }}
            aria-label='basic tabs example'
            centered
          >
            <StyledTab label={<span>🪙&nbsp;&nbsp;Amortissement</span>} {...a11yProps(0)} />
            <StyledTab label={<span>📈&nbsp;&nbsp;Croissance CA / mois</span>} {...a11yProps(1)} />
          </Tabs>
          {/* Amortissement */}
          <CustomTabPanel value={tabValue} index={SubscriptionTabs.ALLOWANCE}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              p={2}
              boxShadow={2}
              borderRadius={2}
              style={{
                maxWidth: 350,
                margin: 'auto',
                marginTop: 10,
                border: `2px solid ${darken(theme.palette.primary.main, 0.2)}`,
              }}
            >
              <Grid container>
                <Grid item xs={12} style={flexCenter}>
                  <Typography variant='h5' component='h2' style={{ color: darken(theme.palette.text.secondary, 0.4) }}>
                    <b>
                      {' '}
                      {currentOffer?.totalPrice &&
                        new Intl.NumberFormat('fr-FR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(getRdvRatioCost())}{' '}
                      €
                    </b>
                  </Typography>
                  <Typography
                    variant='h6'
                    component='h3'
                    style={{ marginLeft: 5, color: darken(theme.palette.text.secondary, 0.4) }}
                  >
                    /rdv <b>*</b>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CustomTabPanel>

          {/* Gains perçus */}
          <CustomTabPanel value={tabValue} index={SubscriptionTabs.GAIN}>
            <Grid container spacing={1} style={flexCenter}>
              {/* MO */}
              <Grid item xs={4} padding={2}>
                <Typography
                  variant='h6'
                  style={flexCenter}
                  sx={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }}
                >
                  <b>MO</b>
                </Typography>
                <Box sx={{ border: `3px solid ${'#F29ABA'}`, m: 1, py: 1 }} p={2} boxShadow={3} borderRadius={2}>
                  <FormControl variant='standard' sx={{ width: '50%', display: 'block', m: 'auto' }}>
                    <Input
                      id='average-rate'
                      type='number'
                      inputProps={{ min: '0' }}
                      value={formValues.averageRate}
                      onChange={(e) => {
                        handleInputChange(e, 'averageRate');
                      }}
                      endAdornment={<InputAdornment position='end'>H</InputAdornment>}
                    />
                  </FormControl>
                  <Typography
                    variant='h6'
                    sx={{
                      color: '#F29ABA',
                      mb: 0.5,
                      fontFamily: 'caveat',
                      fontSize: '1.9rem',
                      fontWeight: 'bold',
                    }}
                    style={flexCenter}
                  >
                    {formValues.averageRate * formValues.averageRateRatio} € / mois
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant='subtitle2'
                    style={flexCenter}
                    sx={{
                      color: theme.palette.text.secondary,
                      textAlign: 'center',
                      fontSize: '0.8rem',
                    }}
                  >
                    taux moyen &nbsp;
                    <FormControl variant='standard' sx={{ width: '20%' }}>
                      <Input
                        id='average-rate-ratio'
                        type='number'
                        inputProps={{ min: '0' }}
                        value={formValues.averageRateRatio}
                        onChange={(e) => {
                          handleInputChange(e, 'averageRateRatio');
                        }}
                      />
                    </FormControl>
                    &nbsp;€ HT / h
                  </Typography>
                </Box>
              </Grid>
              {/* Amortissement */}
              <Grid item xs={4} padding={2}>
                <Typography
                  variant='h6'
                  style={flexCenter}
                  sx={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }}
                >
                  <b>DEVIS</b>
                </Typography>
                <Box
                  sx={{ border: `3px solid ${theme.palette.secondary.main}`, m: 1, py: 1 }}
                  p={2}
                  boxShadow={2}
                  borderRadius={2}
                >
                  <FormControl variant='standard' sx={{ width: '50%', display: 'block', m: 'auto' }}>
                    <Input
                      id='average-estimate'
                      inputProps={{ min: '0' }}
                      type='number'
                      value={formValues.averageEstimate}
                      onChange={(e) => {
                        handleInputChange(e, 'averageEstimate');
                      }}
                    />
                  </FormControl>
                  <Typography
                    variant='body2'
                    sx={{
                      color: darken(theme.palette.secondary.main, 0.1),
                      mb: 0.5,
                      fontFamily: 'caveat',
                      fontSize: '1.9rem',
                      fontWeight: 'bold',
                    }}
                    style={flexCenter}
                  >
                    {formValues.averageEstimate * formValues.averageEstimateRatio} € / mois
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant='subtitle2'
                    style={flexCenter}
                    sx={{
                      color: theme.palette.text.secondary,
                      textAlign: 'center',
                      fontSize: '0.8rem',
                    }}
                  >
                    montant moyen devis &nbsp;
                    <FormControl variant='standard' sx={{ width: '20%' }}>
                      <Input
                        id='average-estimate-ratio'
                        type='number'
                        inputProps={{ min: '0' }}
                        value={formValues.averageEstimateRatio}
                        onChange={(e) => {
                          handleInputChange(e, 'averageEstimateRatio');
                        }}
                      />
                    </FormControl>
                    &nbsp; €
                  </Typography>
                </Box>
              </Grid>
              {/* Croissance CA */}
              <Grid item xs={4} padding={2}>
                <Typography
                  variant='h6'
                  style={flexCenter}
                  sx={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }}
                >
                  <b>RDV</b>
                </Typography>
                <Box
                  sx={{ border: `3px solid ${theme.palette.primary.main}`, m: 1, py: 1 }}
                  p={2}
                  boxShadow={2}
                  borderRadius={2}
                >
                  <FormControl variant='standard' sx={{ width: '50%', display: 'block', m: 'auto' }}>
                    <Input
                      id='average-rate'
                      type='number'
                      inputProps={{ min: '0' }}
                      value={formValues.averageCostAppointment}
                      onChange={(e) => {
                        handleInputChange(e, 'averageCostAppointment');
                      }}
                    />
                  </FormControl>
                  <Typography
                    variant='body2'
                    sx={{
                      color: darken(theme.palette.primary.main, 0.1),
                      mb: 0.5,
                      fontFamily: 'caveat',
                      fontSize: '1.9rem',
                      fontWeight: 'bold',
                    }}
                    style={flexCenter}
                  >
                    {formValues.averageCostAppointment * formValues.averageCostAppointmentRatio} € / mois
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant='subtitle2'
                    style={flexCenter}
                    sx={{
                      color: theme.palette.text.secondary,
                      textAlign: 'center',
                      fontSize: '0.8rem',
                    }}
                  >
                    montant moyen rdv &nbsp;
                    <FormControl variant='standard' sx={{ width: '20%' }}>
                      <Input
                        id='average-estimate-ratio'
                        type='number'
                        inputProps={{ min: '0' }}
                        value={formValues.averageCostAppointmentRatio}
                        onChange={(e) => {
                          handleInputChange(e, 'averageCostAppointmentRatio');
                        }}
                      />
                    </FormControl>
                    &nbsp;€
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CustomTabPanel>
        </Box>
      </Box>
      }
    </>
  );
};
export default memo(PricingTools, areEqual);
