import { Box, Button, CircularProgress, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { StyledToggleButton } from '@movalib/movalib-commons';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

const PdfViewer = () => {
  const pdfUrls: { [key: string]: string } = {
    CGU: '/documents/CGU.pdf',
    CGU_U: '/documents/CGU_U.pdf',
    CGV: '/documents/CGV.pdf',
  };
  const [numPages, setNumPages] = useState<number>();
  const [selectedPdf, setSelectedPdf] = useState<string>('CGV'); // CGU par défaut
  const theme = useTheme();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  const handlePdfChange = (_event: React.MouseEvent<HTMLElement>, newPdf: string) => {
    if (newPdf !== null) setSelectedPdf(newPdf);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <div style={{ top: '-50px', left: '27px', position: 'absolute', zIndex: 1000 }}>
        <ToggleButtonGroup value={selectedPdf} exclusive onChange={handlePdfChange} aria-label='PDF selection'>
          <StyledToggleButton
            selected={selectedPdf === 'CGV'}
            customSelectedBackgroundColor={theme.palette.primary.main}
            customHoverColor={theme.palette.primary.light}
            value='CGV'
          >
            CGV
          </StyledToggleButton>
          <StyledToggleButton
            selected={selectedPdf === 'CGU'}
            customSelectedBackgroundColor={theme.palette.primary.main}
            customHoverColor={theme.palette.primary.light}
            value='CGU'
          >
            CGU
          </StyledToggleButton>
          <StyledToggleButton
            selected={selectedPdf === 'CGU_U'}
            customSelectedBackgroundColor={theme.palette.primary.main}
            customHoverColor={theme.palette.primary.light}
            value='CGU_U'
          >
            CGU utilisateur
          </StyledToggleButton>
        </ToggleButtonGroup>{' '}
      </div>
      <Box
        sx={{
          overflow: 'auto',
          maxHeight: '750px',
          width: '850px',
          minHeight: '750px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Document file={pdfUrls[selectedPdf]} onLoadSuccess={onDocumentLoadSuccess} loading={<CircularProgress />}>
          {[...Array(numPages).keys()].map((index) => (
            <Page scale={1.4} key={index} pageNumber={index + 1} />
          ))}
        </Document>
      </Box>
    </Box>
  );
};
export default PdfViewer;
