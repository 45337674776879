import type { ChangeEvent, FunctionComponent } from 'react';
import type { PricingPackageBasicProps } from '../PricingPackage';
import { Box, Checkbox, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { styleFormation } from '../pricingTools';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';

interface WebOffersProps extends PricingPackageBasicProps {
  webPage: number;
  webcam: number;
  handleChangeWebPage: (event: any) => void;
  initData?: boolean;
}

export interface WebOffersData {
  id: number;
  name: string;
  description: string;
  offers: Array<{ id: number; include: boolean; optional: boolean; price: number; quantity: number }>;
}

const WebOffers: FunctionComponent<WebOffersProps> = ({
  movaPackage,
  options,
  webPage,
  webcam,
  handleChangeWebPage,
  onlyView,
  initData = false,
  formFormik
}: WebOffersProps) => {
  useEffect(() => {
    handleChangeWebPage({
      target: {
        name: 'webPage',
        checked: formFormik.values.webPage,
      },
    });
  
  }, [formFormik.values.webPage]);

  useEffect(() => {
    handleChangeWebPage({
      target: {
        name: 'webcam',
        checked: formFormik.values.webcam,
      },
    });
  
  }, [formFormik.values.webcam]);

 const handleChangeWeb = (event: ChangeEvent<HTMLInputElement>) => {
  formFormik.setFieldValue('webPage' ,!formFormik.values.webPage)
  formFormik.handleChange(event);
  handleChangeWebPage(event)
  }

  const handleChangeWebcam = (event: ChangeEvent<HTMLInputElement>) => {
    formFormik.setFieldValue('webcam' ,!formFormik.values.webcam)
    formFormik.handleChange(event);
    handleChangeWebPage(event)
    }
  return (
    <React.Fragment>
      {options?.web.map((item: WebOffersData) => (
        <Box key={item.id} style={{ ...flexCenter, ...styleFormation }} sx={{ width: '80%', flexDirection: 'column' }}>
          {item.id === 13 && 
          <Box sx={{ display: 'flex', justifyContent: 'center'}}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox
              size='small'
              sx={{ p: 0 }}
              name='webPage'
              checked={formFormik.values.webPage}
              disabled={onlyView}
              onChange={handleChangeWeb}
            />
            &nbsp;site web&nbsp;          
            </Box>

            <Typography
            variant='body2'
            className='my-color'
            sx={{lineHeight : '1.875'}}

            >
            {webPage !== 0 && ` + ${webPage.toFixed(2)}€/mois`}
            </Typography>
            </Box>
          }

          {item.id === 14 && 
          <Box sx={{ display: 'flex', justifyContent: 'center'}}>
            <Checkbox
              size='small'
              sx={{ p: 0 }}
              name='webcam'
              checked={formFormik.values.webcam}
              disabled={onlyView}
              onChange={handleChangeWebcam}
            />
            &nbsp;webcam &nbsp;<Typography
            variant='body2'
            className='my-color'
            sx={{lineHeight : '1.875'}}
            >
           {webcam !== 0 && ` + ${webcam.toFixed(2)}€`}
            </Typography> 
          </Box>

          }


        </Box>
      ))}
    </React.Fragment>
  );
};

export default WebOffers;
