import { GarageService, type Garage } from '@movalib/movalib-commons';
import { flexStart } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { Alert, Box, Grid, TextField, Typography } from '@mui/material';
import { useState, type FunctionComponent } from 'react';
import { setSnackbar } from '../../../slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import theme from '../../../theme';
import CreditCardIcon from '@mui/icons-material/CreditCardRounded';

interface PaymentAuthorizationProps {
  garage: Garage;
  refreshGarage: () => void;
}
const PaymentAuthorization: FunctionComponent<PaymentAuthorizationProps> = ({ garage, refreshGarage }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<number | undefined>(garage.paymentAuthorizationMinDowntime);

  const handleChangeMinDowntime = (v: string) => {
    const numericValue = Number.parseFloat(v);
    if (Number.isNaN(numericValue) || numericValue < 0) {
      return; // Ne pas exécuter la fonction si la valeur n'est pas valide
    }
    const request: any = { minDowntime: numericValue };

    console.log(request);

    GarageService.updatePaymentAuthorization(garage.id, request)
      .then((response) => {
        if (response.success) {
          setValue(numericValue);
          dispatch(
            setSnackbar({
              open: true,
              message: response.data ?? 'Modification enregistrée',
              severity: 'success',
            }),
          );
          refreshGarage();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: `Impossible ${v ? "d'activer" : ' de désactiver'} l\'option véhicule de prêt pour les clients`,
              severity: 'error',
            }),
          );
        }
      })
      .catch((e) => {
        dispatch(
          setSnackbar({
            open: true,
            message: `Impossible ${v ? "d'activer" : ' de désactiver'} l\'option véhicule de prêt pour les clients`,
            severity: 'error',
          }),
        );
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ pr: 2 }}>
          <Typography variant='h6' color='text.secondary' sx={{ alignSelf: 'center', mb: 2 }}>
            <b>Durée minimale d'immobilisation</b>
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary} sx={{ mb: 1 }}>
            Sécurisez vos demandes de rendez-vous en ligne et réduisez les rendez-vous non honorés avec la demande
            d'empreinte bancaire.
          </Typography>
          <Typography variant='body1' color={theme.palette.text.secondary} sx={{ mb: 3 }}>
            Définissez la durée d’immobilisation à partir de laquelle vous souhaitez déclencher une demande d’empreinte
            bancaire.
          </Typography>
          <Box style={flexStart}>
            <TextField
              value={value !== undefined ? value : ''}
              type='number'
              required
              size='small'
              onClick={(e) => {
                e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
              }}
              onChange={(e) => {
                e.stopPropagation(); // Empêche la propagation de l'événement
                const inputValue = e.target.value;
                const numericValue = Number.parseFloat(inputValue);
                if (!Number.isNaN(numericValue) && numericValue >= 0) {
                  setValue(numericValue); // Met à jour l'état local
                  handleChangeMinDowntime(inputValue); // Appelle la fonction seulement si la valeur est valide
                }
              }}
              error={(value as unknown as number) < 0}
              sx={{ width: '100px' }}
              InputProps={{ inputProps: { min: 0 } }}
            />{' '}
            <Typography variant='body2' sx={{ ml: 3 }} color={theme.palette.text.secondary}>
              {' '}
              Heure(s)
            </Typography>{' '}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Alert sx={{ mt: 2, maxWidth: '650px' }} className='styled-alert' icon={<></>}>
            L'empreinte bancaire permet de confirmer la demande de rendez-vous en ligne. Aucun montant ne sera débité
            des comptes de vos clients et leurs coordonnées bancaires ne seront pas conservées.
          </Alert>
        </Grid>
      </Grid>
    </>
  );
};
export default PaymentAuthorization;
