import { ConfirmationDialog, type Customer, MovaAppType, MovaVehicleForm, type Vehicle, VehicleFullCard, VehicleService as CoreVehicleService, VehicleTire, } from '@movalib/movalib-commons';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import invariant from 'invariant';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CatPlateBg from '../../../assets/images/car_plate_bg.png';
import { useBoolState } from '../../../helpers/hooks/useBoolState';
import { useGarageDeleteCustomerVehicle } from '../../../query/garage/GarageQuery';
import { usePatchVehicleInfos } from '../../../query/vehicle/VehicleQuery';
import { setSnackbar } from '../../../slices/snackbarSlice';
import theme from '../../../theme';
import type { VehicleForm } from './vehicleForm';
import { flexCenterRow } from '../../../helpers/Tools';
import VehicleService from '../../../services/VehicleService';
import type { RootState } from '../../../store';

type EditVehicleProps = {
  open: boolean;
  onClose: () => void;
  customer: Customer;
  vehicle: Vehicle;
  garageId: string;
};

export const EditVehicle = memo(({ open, onClose, customer, vehicle, garageId }: EditVehicleProps) => {
  const dispatch = useDispatch();
  const { isConfirmDeleteVehicleDialogOpen, toggleConfirmDeleteVehicleDialogOpen } = useBoolState(
    false,
    'confirmDeleteVehicleDialogOpen',
  );

  const { mutateAsync: patchVehicle } = usePatchVehicleInfos();
  const { mutateAsync: deleteCustomerVehicle } = useGarageDeleteCustomerVehicle();
  const [foundVehicleId, setFoundVehicleId] = useState<string>(vehicle?.id.toString() ?? '');
  const [vehicleEditMode, setVehicleEditMode] = useState<boolean>(false);
  const [currentUpload, setCurrentUpload] = useState<boolean>(false);
  const [localVehicle, setLocalVehicle] = useState<Vehicle | null>(null);
  const connectedUser = useSelector((state: RootState) => state.user.user); // Récupération du user depuis le state Redux

  useEffect(() => {
    refreshVehicle();
  }, [foundVehicleId]);

  const refreshVehicle = () => {
    VehicleService.getVehicleDetails(dispatch, vehicle.id).then((response) => {
      setLocalVehicle(response);
    });
  }
  const onDeleteVehicle = useCallback(async () => {
    invariant(garageId, 'Garage ID is not defined');

    const response = await deleteCustomerVehicle({
      garageId: garageId,
      vehicleId: `${vehicle.id}`,
      customerId: customer.id,
    });

    if (response.success) {
      dispatch(
        setSnackbar({
          open: true,
          message: response.data ?? 'Véhicule supprimé du fichier client !',
          severity: 'success',
        }),
      );
      onClose();
    } else {
      dispatch(
        setSnackbar({
          open: true,
          message: response.error ?? 'Erreur lors de la suppression du véhicule',
          severity: 'error',
        }),
      );
    }
  }, [customer.id, deleteCustomerVehicle, dispatch, garageId, onClose, vehicle.id]);

  const onSubmitForm = useCallback(
    async (valueForm: MovaVehicleForm) => {
      const query = {
        vehicleId: vehicle.id,
        currentMileage: valueForm.currentMileage.value,
        averageMileagePerYear: valueForm.averageMileagePerYear.value,
        tireWidth:
          valueForm.tireSize.isValid && valueForm.tireSize.value ? (valueForm.tireSize.value as VehicleTire).width : undefined,
        tireHeight:
          valueForm.tireSize.isValid && valueForm.tireSize.value ? (valueForm.tireSize.value as VehicleTire).height : undefined,
        tireDiameter:
          valueForm.tireSize.isValid && valueForm.tireSize.value ? (valueForm.tireSize.value as VehicleTire).diameter : undefined,
        tireSpeedIndex:
          valueForm.tireSize.isValid && valueForm.tireSize.value ? (valueForm.tireSize.value as VehicleTire).speedIndex : undefined,
      };      // Send to API
      const response = await patchVehicle(query);

      if (response.success) {
        dispatch(
          setSnackbar({
            open: true,
            message: response.data ?? 'Véhicule ajouté au fichier client !',
            severity: 'success',
          }),
        );
        onClose();
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error ?? "Erreur lors de l'ajout du véhicule",
            severity: 'error',
          }),
        );
      }
    },
    [dispatch, onClose, patchVehicle, vehicle.id],
  );

  const handleOnVehicleUploadDocument = (data: FormData) => {
    if (vehicle && data)
    {
      //  setLoading(true);
      setCurrentUpload(true);
      // Téléchargement du document
      CoreVehicleService.uploadVehicleDocument(MovaAppType.GARAGE, vehicle.id, data)
        .then((response) => {
          // Affichage notification utilisateur
          setSnackbar({ open: true, message: response.data as string, severity: 'success' });

          refreshVehicle();
        })
        .catch((error: string) => {
          // Logger.info(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        })
        .finally(() => {
          setCurrentUpload(false);
          //setLoading(false);
        });
    }
  };

  const handleOnVehicleDeleteDocument = (documentId: string) => {
    if (vehicle && documentId)
    {
      //setLoading(true);

      // Récupération des documents du véhicule
      CoreVehicleService.deleteVehicleDocument(MovaAppType.GARAGE, vehicle.id, documentId)
        .then((response) => {
          // Logger.info(response);

          // Affichage notification utilisateur
          dispatch(setSnackbar({ open: true, message: response.data as string, severity: 'success' }));

          refreshVehicle();
        })
        .catch((error) => {
          //Logger.info(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        })
        .finally(() => {
          //setLoading(false);
        });
    }
  };
  const handleOnVehicleError = (message: string) => {
    if (message)
    {
      //Logger.error(message);
      dispatch(setSnackbar({ open: true, message: message, severity: 'error' }));
    }
  };
  return (
    <>
      <Dialog open={open} maxWidth='sm' scroll='body' onClose={onClose} fullWidth>
        <Toolbar
          disableGutters
          variant='dense'
          sx={{
            minHeight: 3,
            backgroundColor: theme.palette.grey[200],
            py: 0,
          }}
        >
          <DialogTitle sx={{ flexGrow: 1 }} component={'div'}>
            <Typography
              py={0}
              my={0}
              sx={{
                pl: '34px',
                color: theme.palette.text.primary,
                textAlign: 'center',
              }}
            >
              <span>
                MODIFIER <b>UN VÉHICULE</b>
              </span>
            </Typography>
          </DialogTitle>
          <Tooltip title='Fermer'>
            <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>

        <DialogContent sx={{
          marginBottom: '24px',
          p: 0,
          maxHeight: 'calc(var(--fullHeight) - 68px - 54px - 64px)',
          position: 'relative',
          overflowX: 'hidden',
        }}>
          <Grid container style={flexCenterRow}>
            {localVehicle && <VehicleFullCard
              onDelete={onDeleteVehicle}
              currentUser={connectedUser!}
              currentUpload={currentUpload}
              vehicle={localVehicle}
              onError={handleOnVehicleError}
              onUploadDocument={handleOnVehicleUploadDocument}
              onDeleteDocument={handleOnVehicleDeleteDocument}
              onUpdate={onSubmitForm}
              editMode={vehicleEditMode}
              appType={MovaAppType.GARAGE}
            />}
          </Grid>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        open={isConfirmDeleteVehicleDialogOpen}
        onClose={toggleConfirmDeleteVehicleDialogOpen}
        onConfirm={onDeleteVehicle}
        title='Supprimer le véhicule'
        message='Êtes-vous sûr de vouloir supprimer le véhicule assigné à ce client ?'
      />
    </>
  );
});
