import {
  type Absence,
  ConfirmationDialog,
  DayOfWeek,
  type DaySchedule,
  type Employee,
  type Garage,
  GarageService,
  type MovaInterval,
  type Prestation,
  Schedule,
  ScheduleFields,
  capitalizeFirstLetter,
} from '@movalib/movalib-commons';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { AddRounded, ClearRounded } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
  darken,
  lighten,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import {
  DataGrid,
  type GridColDef,
  type GridRenderCellParams,
  type GridRowParams,
  type GridRowSelectionModel,
  type GridTreeNodeWithRender,
} from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import type React from 'react';
import { type FunctionComponent, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import AvatarSelector from '../components/AvatarSelector';
import UserAvatar from '../components/UserAvatar';
import AddEmployeeDialog from '../dialogs/AddEmployeeDialog';
import { StyledDialogTitle } from '../helpers/Styled';
import { flexEnd, flexStart, getDayOfWeekFromString, getDaysDiff, importIcon } from '../helpers/Tools';
import { useBoolState } from '../helpers/hooks/useBoolState';
import { useFetchEmployees } from '../query/employee/EmployeeQuery';
import { useFetchAdministratedGarages } from '../query/garage/GarageQuery'; // Import du thème personnalisé
import { setSnackbar } from '../slices/snackbarSlice';
import theme from '../theme';
import moment from 'moment-timezone';

enum AvailabilityType {
  GARAGE = 'GARAGE',
  OFF = 'OFF',
  CUSTOM = 'CUSTOM',
}

interface EmployeesProps {
  isAdmin: boolean;
  garage: Garage
}

const Employees: FunctionComponent<EmployeesProps> = ({ isAdmin, garage }) => {
  const dispatch = useDispatch();

  const { data: employees = [], refetch: refreshEmployees } = useFetchEmployees(garage?.id);

  const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(undefined);
  const [selectedAvailabilityEdit, setSelectedAvailabilityEdit] = useState<Schedule | null>(null);
  const [editAvailabilityDialogOpen, setEditAvailabilityDialogOpen] = useState(false);
  const [editEmployeeDialogOpen, setEditEmployeeDialogOpen] = useState(false);
  const [addAbsenceDialogOpen, setAddAbsenceDialogOpen] = useState(false);
  const [addSpecialtyDialogOpen, setAddSpecialtyDialogOpen] = useState(false);
  const [absenceStart, setAbsenceStart] = useState<Date | null>(null);
  const [absenceEnd, setAbsenceEnd] = useState<Date | null>(null);
  const [selectedPrestation, setSelectedPrestation] = useState<string>('');
  // Used in renderAvailabilityEditDialog
  const [availabilityType, setAvailabilityType] = useState<AvailabilityType>();
  const [endTime, setEndTime] = useState<string>();
  // Récupération des données garage pour l'utilisateur connecté
  const [openAddEmployee, setOpenAddEmployee] = useState<boolean>(false);
  const dataGridRef = useRef<HTMLDivElement>(null);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [editedSchedules, setEditedSchedules] = useState<Schedule[]>([]);
  const [editedAvatar, setEditedAvatar] = useState<string | null>(null);
  const [editedFirstname, setEditedFirstname] = useState<string | null>(null);
  const [editedLastname, setEditedLastname] = useState<string | null>(null);
  const { isConfirmationDialogOpen, toggleConfirmationDialogOpen } = useBoolState(false, 'confirmationDialogOpen');

  const handleClickOutside = (event: MouseEvent) => {
    if (dataGridRef.current && !dataGridRef.current.contains(event.target as Node)) {
      setSelectedEmployee(undefined);
      setSelectionModel([]); // Clear selection
    }
  };

  /*   useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); */

  const isGarageWorkingDay = (day: DayOfWeek, intervalIndex: number) => {
    if (!garage?.schedules) {
      return false;
    }

    const schedule = garage?.schedules.find((s) => s.dayOfWeek === day);

    return (
      schedule &&
      !(schedule.intervals[intervalIndex].startTime === '' && schedule.intervals[intervalIndex].endTime === '')
    );
  };

  const employeesColumns: GridColDef[] = [
    {
      field: 'avatar',
      headerName: 'Avatar',
      width: 60,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Avatar src={params.value} sx={{ mt: 0.5 }} onClick={handleOpenEditEmployee} />
          </>
        );
      },
    },
    {
      field: 'fullname',
      headerName: 'Réparateur',
      width: 150,
      valueGetter: (value, row) => `${row.firstname || ''} ${row.lastname || ''}`,
      renderCell: (params) => (
        <Box sx={{ fontWeight: 'bold' }} onClick={handleOpenEditEmployee}>
          {params.value}
        </Box>
      ),
    },
    {
      field: 'monday',
      headerName: 'Lundi',
      sortable: false,
      display: 'flex',
      width: 250,
      renderCell: (params) => renderEmployeesCell(params, DayOfWeek.MONDAY),
    },
    {
      field: 'tuesday',
      headerName: 'Mardi',
      sortable: false,
      display: 'flex',
      width: 250,
      renderCell: (params) => renderEmployeesCell(params, DayOfWeek.TUESDAY),
    },
    {
      field: 'wednesday',
      headerName: 'Mercredi',
      sortable: false,
      display: 'flex',
      width: 250,
      renderCell: (params) => renderEmployeesCell(params, DayOfWeek.WEDNESDAY),
    },
    {
      field: 'thursday',
      headerName: 'Jeudi',
      sortable: false,
      display: 'flex',
      width: 250,
      renderCell: (params) => renderEmployeesCell(params, DayOfWeek.THURSDAY),
    },
    {
      field: 'friday',
      headerName: 'Vendredi',
      sortable: false,
      display: 'flex',
      width: 250,
      renderCell: (params) => renderEmployeesCell(params, DayOfWeek.FRIDAY),
    },
    {
      field: 'saturday',
      headerName: 'Samedi',
      sortable: false,
      display: 'flex',
      width: 250,
      renderCell: (params) => renderEmployeesCell(params, DayOfWeek.SATURDAY),
    },
    {
      field: 'sunday',
      headerName: 'Dimanche',
      sortable: false,
      display: 'flex',
      width: 250,
      renderCell: (params) => renderEmployeesCell(params, DayOfWeek.SUNDAY),
    },
  ];

  // Filtrer les colonnes pour inclure uniquement celles où le garage travaille ce jour-là
  const filteredEmployeesColumns = employeesColumns.filter((column) => {
    if (column.field === 'avatar' || column.field === 'fullname') {
      return true; // Garder les colonnes qui ne sont pas les horaires
    }

    return (
      garage &&
      (garage?.schedules as Schedule[])
        .filter((schedule) => schedule.dayOfWeek === getDayOfWeekFromString(column.field))
        .some((schedule) => isGarageWorkingDay(schedule.dayOfWeek, 0))
    );
  });

  const renderEmployeesCell = (
    params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
    dayOfWeek: DayOfWeek,
  ): JSX.Element => {
    if (params) {
      const schedules = (params.row.schedules as Schedule[]) || [];
      const filteredSchedules = schedules.filter((s) => s.dayOfWeek === dayOfWeek);

      if (filteredSchedules.length === 0) {
        return (
          <Button
            color='inherit'
            sx={{ opacity: 0.7 }}
            style={flexCenter}
            onClick={(e) => handleAvailabilityEditClick(params.row.id, dayOfWeek, 0)}
          >
            <NotInterestedIcon />
            &nbsp;&nbsp;OFF
          </Button>
        ); // Rendu par défaut
      }

      return (
        <>{filteredSchedules.flatMap((schedule, index) => renderEmployeeSchedule(params.row.id, schedule, index))}</>
      );
    }
    return <></>;
  };

  const renderEmployeeSchedule = (employeeId: string, schedule: Schedule, index: number) => {
    return schedule.intervals.map((interval, indexInt) => (
      <Button
        key={`${index}-${indexInt}`} // Ajout d'une clé unique combinée
        color='inherit'
        sx={{
          m: 0.5,
          backgroundColor: isGarageWorkingDay(schedule.dayOfWeek, index) ? theme.palette.primary.light : 'transparent',
        }}
        disabled={!isGarageWorkingDay(schedule.dayOfWeek, index)}
        onClick={(e) => handleAvailabilityEditClick(employeeId, schedule.dayOfWeek, indexInt)}
      >
        <Typography variant='body2'>
          {isWorkingDay(interval)
            ? `${(interval.startTime as string)?.slice(0, -3)} - ${(interval.endTime as string)?.slice(0, -3)}`
            : 'OFF'}
        </Typography>
      </Button>
    ));
  };

  const handleOpenEditEmployee = () => {
    if (!selectedEmployee) {
      return;
    }

    setEditEmployeeDialogOpen(true);
  };

  const handleEmployeeChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const employeeId = event.target.value;
    const employee = employees.find((employee) => employee.id === employeeId) || null;

    reloadSelectedEmployed(garage, employee);
  };

  const reloadSelectedEmployed = (garage: Garage | null | undefined, employee: Employee | null) => {
    if (garage && employee) {
      // Chargement du détail de l'employé sélectionné
      GarageService.getEmployeeDetails(garage.id, employee.id)
        .then((response) => {
          if (response.success) {
            setSelectedEmployee(response?.data);
          } else {
            dispatch(
              setSnackbar({
                open: true,
                message: response.error ?? 'Erreur lors de la récupération des données du technicien',
                severity: 'error',
              }),
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  //On détermine le type de disponibilité fonction des planning technicien et garage
  const setDefaultAvailabilityType = (technicianSchedule: MovaInterval, garageSchedule: MovaInterval) => {
    if (
      technicianSchedule.startTime === garageSchedule.startTime &&
      technicianSchedule.endTime === garageSchedule.endTime
    ) {
      setAvailabilityType(AvailabilityType.GARAGE);
    }

    if (
      technicianSchedule.startTime !== garageSchedule.startTime ||
      technicianSchedule.endTime !== garageSchedule.endTime
    ) {
      setAvailabilityType(AvailabilityType.CUSTOM);
    }

    if (technicianSchedule.startTime === '' && technicianSchedule.endTime === '') {
      setAvailabilityType(AvailabilityType.OFF);
    }
  };

  const handleAvailabilityEditClick = (employeeId: string, day: DayOfWeek, intervalIndex: number): void => {
    const employee = employees.find((employee) => employee.id === employeeId);

    if (employee !== undefined) {
      const schedule = employee.schedules.find((item) => item.dayOfWeek === day);

      if (garage?.schedules) {
        const garageSchedule = garage.schedules.find((s) => s.dayOfWeek === day);

        //if(garageSchedule)
        //setDefaultAvailabilityType(schedule.intervals[intervalIndex], garageSchedule.intervals[intervalIndex]);

        //setSelectedAvailabilityEdit(schedule);
        setEditedSchedules(employee.schedules);
        setEditAvailabilityDialogOpen(true);
      }
    }
  };

  const handleAddAbsenceClick = () => {
    setAddAbsenceDialogOpen(true);
  };

  const handleAddSpecialtyClick = () => {
    setAddSpecialtyDialogOpen(true);
  };

  const handleAbsenceStartChange = (date: string | Date | null) => {
    setAbsenceStart(date ? new Date(date) : null);
  };

  const handleAbsenceEndChange = (date: string | Date | null) => {
    setAbsenceEnd(date ? new Date(date) : null);
  };

  const handleServiceSelectChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    setSelectedPrestation(event.target.value);
  };

  const handleSaveAvailability = () => {
    if (!(garage && selectedEmployee && editedSchedules)) {
      return;
    }

    const request = {
      schedules: editedSchedules,
    };

    GarageService.updateGarageEmployeeSchedules(garage.id, selectedEmployee.id, request)
      .then((response) => {
        if (response.success) {
          reloadSelectedEmployed(garage, selectedEmployee);
          refreshEmployees();
          setEditedSchedules([]);
          setEditAvailabilityDialogOpen(false);
          dispatch(
            setSnackbar({
              open: true,
              message: response.data ?? 'La disponibilité du réparateur a bien été modifiée',
              severity: 'success',
            }),
          );
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Erreur lors de la modification de la disponibilité',
              severity: 'error',
            }),
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAddAbsence = () => {
    if (!(garage && selectedEmployee && absenceStart && absenceEnd)) {
      return;
    }

    // Check if absenceEnd is not earlier than absenceStart
    if (absenceEnd < absenceStart) {
      dispatch(
        setSnackbar({
          open: true,
          message: "La date de fin d'absence ne peut pas être antérieure à la date de début",
          severity: 'error',
        }),
      );
      return;
    }

    const request = {
      startDate: absenceStart,
      endDate: absenceEnd,
    };

    GarageService.createGarageEmployeeAbsence(garage.id, selectedEmployee.id, request)
      .then((response) => {
        if (response.success) {
          reloadSelectedEmployed(garage, selectedEmployee);
          refreshEmployees();
          setAbsenceStart(null);
          setAbsenceEnd(null);
          setAddAbsenceDialogOpen(false);
          dispatch(
            setSnackbar({
              open: true,
              message: response.data ?? "L'absence a bien été ajoutée au réparateur",
              severity: 'success',
            }),
          );
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Erreur lors de l'ajout de l'absence",
              severity: 'error',
            }),
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeleteAbsence = (absence: Absence) => {
    if (!(garage && selectedEmployee && absence)) {
      return;
    }

    GarageService.deleteGarageEmployeeAbsence(garage.id, selectedEmployee.id, String(absence.id))
      .then((response) => {
        if (response.success) {
          reloadSelectedEmployed(garage, selectedEmployee);
          refreshEmployees();
          dispatch(
            setSnackbar({
              open: true,
              message: response.data ?? "L'absence a bien été supprimée",
              severity: 'success',
            }),
          );
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Erreur lors de la suppression de l'absence",
              severity: 'error',
            }),
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeletePrestation = (prestation: Prestation) => {
    if (!(garage && selectedEmployee && prestation)) {
      return;
    }

    GarageService.deleteGarageEmployeePrestation(garage.id, selectedEmployee.id, String(prestation.id))
      .then((response) => {
        if (response.success) {
          reloadSelectedEmployed(garage, selectedEmployee);
          refreshEmployees();
          dispatch(
            setSnackbar({
              open: true,
              message: response.data ?? 'La prestation a bien été supprimée',
              severity: 'success',
            }),
          );
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Erreur lors de l'ajout de la prestation",
              severity: 'error',
            }),
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAddPrestation = () => {
    if (!(garage && selectedEmployee && selectedPrestation)) {
      return;
    }

    GarageService.createGarageEmployeePrestation(garage.id, selectedEmployee.id, selectedPrestation)
      .then((response) => {
        if (response.success) {
          reloadSelectedEmployed(garage, selectedEmployee);
          refreshEmployees();
          setSelectedPrestation('');
          setAddSpecialtyDialogOpen(false);
          dispatch(
            setSnackbar({
              open: true,
              message: response.data ?? 'La prestation a bien été ajoutée au réparateur',
              severity: 'success',
            }),
          );
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Erreur lors de l'ajout de la prestation",
              severity: 'error',
            }),
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCloseAddAbsence = () => {
    setAbsenceStart(null);
    setAbsenceEnd(null);
    setAddAbsenceDialogOpen(false);
  };

  const handleCloseAddPrestation = () => {
    setSelectedPrestation('');
    setAddSpecialtyDialogOpen(false);
  };

  const handleCloseEditAvailability = () => {
    setEditAvailabilityDialogOpen(false);
    setAvailabilityType(undefined);
  };

  const renderAbsences = () => {
    if (!selectedEmployee) {
      return null;
    }

    // Sort absences by start date in ascending order
    const sortedAbsences = selectedEmployee.absences
      ?.slice()
      .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

    return sortedAbsences?.map((absence, index) => {
      const startDate = new Date(absence.startDate);
      const endDate = new Date(absence.endDate);
      const daysDiff = getDaysDiff(startDate, endDate);

      return (
        <Box key={index} display='flex' justifyContent='left' alignItems='center' mt={1}>
          <Typography>
            Du <b>{formatDate(startDate)}</b> au <b>{formatDate(endDate)}</b>&nbsp;&nbsp; ({daysDiff} &nbsp;jour
            {daysDiff > 1 ? 's' : ''})
          </Typography>
          <Tooltip title='Supprimer'>
            <IconButton sx={{ ml: 1 }} onClick={() => handleDeleteAbsence(absence)}>
              <ClearRounded sx={{ color: theme.palette.error.dark }} />
            </IconButton>
          </Tooltip>
        </Box>
      );
    });
  };

  const renderPrestations = () => {
    if (!selectedEmployee) {
      return null;
    }

    // Sort prestations alphabetically by name
    const sortedPrestations = [...selectedEmployee.prestations].sort((a, b) => a.name.localeCompare(b.name));

    // Split prestations into two columns
    const half = Math.ceil(sortedPrestations.length / 2);
    const firstColumn = sortedPrestations.slice(0, half);
    const secondColumn = sortedPrestations.slice(half);

    const renderColumn = (column: typeof sortedPrestations) =>
      column.map((prestation, index) => (
        <Grid container key={index} display='flex' justifyContent='left' alignItems='center' mt={0.5}>
          <Grid item xs={8} display='flex' alignItems='center'>
            <img
              src={importIcon(prestation.code)}
              alt={prestation.code}
              style={{ width: '24px', height: '24px', marginRight: '8px' }}
            />
            <Typography sx={{ fontSize: '0.9rem' }}>{prestation.name}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title='Supprimer'>
              <IconButton sx={{ ml: 1 }} onClick={() => handleDeletePrestation(prestation)}>
                <ClearRounded sx={{ color: theme.palette.error.dark }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ));

    return (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          {renderColumn(firstColumn)}
        </Grid>
        <Grid item xs={6}>
          {renderColumn(secondColumn)}
        </Grid>
      </Grid>
    );
  };

  const renderAvailabilityEditDialog = () => {
    if (!selectedEmployee) {
      return null;
    }

    const { firstname, lastname, avatar, schedules } = selectedEmployee;

    const handleScheduleChange = (schedules: DaySchedule[]) => {
      if (schedules !== undefined) {
        // On contrôle l'absence d'erreur dans le tableau de schedule
        const hasErrors = schedules.some((day) => day.intervals.some((interval) => interval.error !== null));

        if (!hasErrors) {
          // On crée un objet Schedule sur la base du DaySchedule reçu de ScheduleFields
          const newSchedules = new Array<Schedule>();
          schedules.forEach((s) => {
            // Seuls les journées actives (cochées) sont envoyées
            if (s.checked) {
              newSchedules.push(
                new Schedule(
                  s.day,
                  s.intervals.map(({ startTime, endTime, countryCode }) => ({
                    startTime: startTime ? format(new Date(startTime), 'HH:mm') : null,
                    endTime: endTime ? format(new Date(endTime), 'HH:mm') : null,
                    countryCode,
                  })),
                  true,
                ),
              );
            }
          });
          setEditedSchedules(newSchedules);
        }
      } else {
        setEditedSchedules([]);
      }
    };

    return (
      <Dialog open={editAvailabilityDialogOpen} onClose={handleCloseEditAvailability} maxWidth='md'>
        <StyledDialogTitle>
          <Grid container>
            <Grid item xs={9}>
              Modifier disponibilité
            </Grid>
            <Grid item xs={3} style={flexEnd}>
              <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={handleCloseEditAvailability}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </StyledDialogTitle>
        <DialogContent>
          <Box component='div' display='flex' alignItems='center' mb={3}>
            <UserAvatar avatar={avatar} alt={firstname} />
            <Typography>
              <b>
                {firstname} {lastname}
              </b>
            </Typography>
          </Box>
          <ScheduleFields schedules={schedules} size='small' onChange={handleScheduleChange} timePickerStep={30} />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='inherit' onClick={handleCloseEditAvailability} sx={{ mr: 2, width: 150 }}>
            Annuler
          </Button>
          <Button variant='contained' onClick={handleSaveAvailability} startIcon={<SaveOutlined />}>
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleDeleteEmployee = () => {
    if (!(garage && selectedEmployee)) {
      return null;
    }

    GarageService.deleteGarageEmployee(garage.id, selectedEmployee.id)
      .then((response) => {
        if (response.success) {
          setSelectedEmployee(undefined);
          refreshEmployees();
          setSelectionModel([]); // Clear selection
          toggleConfirmationDialogOpen();
          dispatch(
            setSnackbar({
              open: true,
              message: response.data ?? 'Le réparateur a bien été supprimé',
              severity: 'success',
            }),
          );
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Erreur lors de la suppression du réparateur',
              severity: 'error',
            }),
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderEmployeeEditDialog = () => {
    if (!selectedEmployee) {
      return null;
    }

    const { firstname, lastname, avatar } = selectedEmployee;

    const handleCloseEmployeeEditDialog = () => {
      setEditEmployeeDialogOpen(false);
    };

    const handleAvatarChange = (avatar: string | null) => {
      setEditedAvatar(avatar);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const fieldName: string = e.target.name;
      let fieldValue: string = e.target.value;

      // Capitalisation automatique du prénom
      if (fieldName === 'firstname') {
        fieldValue = capitalizeFirstLetter(fieldValue);
        setEditedFirstname(fieldValue);
      }

      // Uppercase automatique pour le nom
      if (fieldName === 'lastname') {
        fieldValue = fieldValue.toUpperCase();
        setEditedLastname(fieldValue);
      }
    };

    const handleEditEmployee = () => {
      if (!(garage && firstname && lastname && avatar)) {
        return;
      }

      const request = {
        avatar: editedAvatar,
        firstname: editedFirstname,
        lastname: editedLastname,
      };

      GarageService.updateGarageEmployee(garage.id, selectedEmployee.id, request)
        .then((response) => {
          if (response.success) {
            reloadSelectedEmployed(garage, selectedEmployee);
            refreshEmployees();
            setEditEmployeeDialogOpen(false);
            dispatch(
              setSnackbar({
                open: true,
                message: response.data ?? 'Le réparateur a bien été modifié',
                severity: 'success',
              }),
            );
          } else {
            dispatch(
              setSnackbar({
                open: true,
                message: response.error ?? 'Erreur lors de la modification du réparateur',
                severity: 'error',
              }),
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    return (
      <Dialog open={editEmployeeDialogOpen} onClose={handleCloseEmployeeEditDialog}>
        <StyledDialogTitle>
          Modifier le réparateur
          <Tooltip title='Fermer'>
            <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={handleCloseEmployeeEditDialog}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </StyledDialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={5} style={flexCenter}>
              <AvatarSelector
                firstName={firstname}
                lastName={lastname}
                onChange={handleAvatarChange}
                avatarBase64={avatar}
                editMode
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                margin='normal'
                required
                autoFocus
                size='small'
                fullWidth
                id='firstname'
                label='Prénom'
                name='firstname'
                autoComplete='given-name'
                onChange={(e) => handleInputChange(e)}
                value={editedFirstname}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                size='small'
                id='lastname'
                label='Nom'
                name='lastname'
                autoComplete='family-name'
                onChange={(e) => handleInputChange(e)}
                value={editedLastname}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleEditEmployee} startIcon={<SaveOutlined />}>
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderAddAbsenceDialog = () => {
    if (!selectedEmployee) {
      return null;
    }

    const { firstname, lastname, avatar } = selectedEmployee;
    const daysDiff = absenceEnd && absenceStart ? getDaysDiff(absenceStart, absenceEnd) : null;

    return (
      <Dialog open={addAbsenceDialogOpen} onClose={handleCloseAddAbsence}>
        <StyledDialogTitle>
          Ajouter une absence
          <Tooltip title='Fermer'>
            <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={handleCloseAddAbsence}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </StyledDialogTitle>
        <DialogContent>
          <Grid container alignItems='center' mb={2}>
            <Grid item xs={6} display='flex' alignItems='center'>
              <UserAvatar avatar={avatar} alt={firstname} />
              <Typography>
                <b>
                  {firstname} {lastname}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign='center'>
              {daysDiff !== null && (
                <Typography>
                  Nombre de jour(s) : &nbsp;&nbsp;<b>{daysDiff}</b>
                </Typography>
              )}
            </Grid>
          </Grid>
          <Box display='flex' alignItems='center' mb={2}>
            <DatePicker
              label='Début'
              value={absenceStart}
              format='dd/MM/yyyy'
              formatDensity='dense'
              views={['day']}
              displayWeekNumber
              onChange={(value) => handleAbsenceStartChange(value)}
              slotProps={{
                textField: {
                  sx: {
                    padding: 0,
                  },
                  variant: 'outlined',
                  margin: 'normal',
                },
              }}
            />

            <Box mx={2} />

            <DatePicker
              label='Fin'
              value={absenceEnd}
              format='dd/MM/yyyy'
              formatDensity='dense'
              views={['day']}
              displayWeekNumber
              onChange={(value) => handleAbsenceEndChange(value)}
              slotProps={{
                textField: {
                  sx: {
                    padding: 0,
                  },
                  variant: 'outlined',
                  margin: 'normal',
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleAddAbsence} startIcon={<SaveOutlined />}>
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderAddPrestationDialog = () => {
    if (!selectedEmployee) return null;

    const { firstname, lastname, avatar } = selectedEmployee;

    return (
      <Dialog fullWidth={true} maxWidth={'xs'} open={addSpecialtyDialogOpen} onClose={handleCloseAddPrestation}>
        <StyledDialogTitle>
          Ajouter une prestation
          <Tooltip title='Fermer'>
            <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={handleCloseAddPrestation}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </StyledDialogTitle>
        <DialogContent>
          <Box display='flex' alignItems='center' mb={4}>
            <UserAvatar avatar={avatar} alt={firstname} />
            <Typography>
              <b>
                {firstname} {lastname}
              </b>
            </Typography>
          </Box>
          <Box display='flex' alignItems='center' mb={2}>
            <FormControl fullWidth>
              <InputLabel id='operator-select-label'>Choisissez une prestation...</InputLabel>
              <Select
                value={selectedPrestation}
                onChange={handleServiceSelectChange}
                variant='outlined'
                fullWidth
                margin='dense'
                label='Choisissez une prestation...'
              >
                {garage?.prestations
                  .filter((p) => p.code !== 'OTHER')
                  .filter((p) => !selectedEmployee?.prestations?.some((prest) => prest.id === p.id))
                  .sort((a, b) => a.name.localeCompare(b.name)) // Tri par ordre alphabétique
                  .map((prestation) => (
                    <MenuItem key={prestation.id} value={prestation.id}>
                      <Box display='flex' alignItems='center'>
                        {prestation.code !== 'OTHER' && (
                          <img
                            src={importIcon(prestation.code)}
                            alt={prestation.code}
                            style={{ width: '24px', height: '24px', marginRight: '8px' }}
                          />
                        )}
                        <Typography>{prestation.name}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleAddPrestation} startIcon={<SaveOutlined />}>
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('fr-FR', options);
  };

  const isWorkingDay = (interval: MovaInterval) => {
    return !(interval.startTime === '' && interval.endTime === '');
  };

  const handleOpenAddEmployee = () => {
    setOpenAddEmployee(true);
  };

  const handleCloseAddEmployee = (refresh: boolean) => {
    if (refresh) refreshEmployees();

    setOpenAddEmployee(false);
  };

  const handleEmployeeRowClick = (params: GridRowParams) => {
    if (params.row) {
      setSelectedEmployee(params.row);
      setEditedAvatar(params.row.avatar);
      setEditedFirstname(params.row.firstname);
      setEditedLastname(params.row.lastname);
    }
  };

  return (
    <Grid container sx={{ pt: {md:9, xs:5}, pl: 2 }}>
      <Grid item xs={12}>
        <Grid container sx={{ alignItems: 'center', position: 'static', mt: 2 }}>
          <Grid item xs={12} md={3} alignItems='center' sx={{ mb: 1, pt: 1, display: {xs:'flex', md:'block'} }}>
            <Button
              variant='contained'
              size='small'
              startIcon={<AddRoundedIcon />}
              color='primary'
              onClick={handleOpenAddEmployee}
              sx={{
                pr: 2,
                mr: 4,
                mx:{xs: 'auto', md: ''},
                textTransform: 'none',
              }}
            >
              Ajouter un réparateur
            </Button>
          </Grid>
          {employees && employees.length > 0 && (
            <>
              <Grid item xs={12} md={6} style={flexCenter}>
                <Typography
                  style={flexStart}
                  color={theme.palette.text.primary}
                  sx={{
                    fontFamily: 'outfit',
                    fontSize: { xs: '1rem', md:'1.2rem'},
                    borderRadius: 4,
                    pr: 2,
                    pl: 2,
                    borderRight: `7px solid ${theme.palette.primary.main}`,
                    borderLeft: `7px solid ${theme.palette.primary.main}`,
                    color: darken(theme.palette.primary.main, 0.4),
                  }}
                >
                  {selectedEmployee ? (
                    <>
                      <b>
                        {selectedEmployee.firstname} {selectedEmployee.lastname}
                      </b>
                    </>
                  ) : (
                    'Sélectionnez un réparateur pour le modifier'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3} style={flexEnd}>
                {selectedEmployee && (
                  <Button
                    variant='contained'
                    size='small'
                    color='error'
                    onClick={toggleConfirmationDialogOpen}
                    sx={{
                      pr: 2,
                      mr: 4,
                      textTransform: 'none',
                    }}
                  >
                    Supprimer le réparateur
                  </Button>
                )}
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            {employees && employees.length > 0 ? (
              <>
                {/*                   <Table sx={{ pr: 1, pt: 1}} stickyHeader size="small">
                      <TableHead sx={{ backgroundLabel: theme.palette.grey[200], fontWeight:'bold'}}>
                          <TableRow>
                              <TableCell width='40%' sx={{border:0}} />
                              {garage?.schedules
                                .sort((a, b) =>  Object.values(DayOfWeek).indexOf(a.dayOfWeek) - Object.values(DayOfWeek).indexOf(b.dayOfWeek))
                                .map((schedule) => (
                                  <StyledTableCell key={schedule.dayOfWeek} width='20%'>
                                      {getDayOfWeekLabel(schedule.dayOfWeek)}
                                  </StyledTableCell>
                              ))}
                          </TableRow>
                      </TableHead>
                      <TableBody sx={{p:0}}>
                          <TableRow>
                            <TableCell />
                            <TableCell colSpan={5} align='center'>
                              <Typography>Horaires Garage</Typography>
                            </TableCell>
                            </TableRow>
                          <TableRow>
                            <TableCell />
                            {garage?.schedules.map((schedule) => (
                                <TableCell key={schedule.dayOfWeek} padding='none' sx={{pl:1}}>
                                    {schedule.intervals.map((interval, index) => (
                                      <>
                                        {isGarageWorkingDay(schedule.dayOfWeek, index) ?
                                          `${(garage.schedules[index].intervals[index].startTime as string)?.slice(0,-3)} - ${(garage?.schedules[index].intervals[index].endTime as string)?.slice(0,-3)}`
                                          : 'OFF'}
                                          <br />
                                      </>
                                    ))}
                                </TableCell>
                            ))}
                          </TableRow>

                      </TableBody>
                  </Table> */}
                <div style={{ width: '100%' }} ref={dataGridRef}>
                  <DataGrid
                    rows={employees}
                    columns={filteredEmployeesColumns}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'fullname', sort: 'asc' }], // Trier initialement par 'firstname' en ordre croissant
                      },
                    }}
                    hideFooter
                    hideFooterPagination
                    onRowClick={handleEmployeeRowClick}
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={(newSelection) => setSelectionModel(newSelection)}
                    sx={{
                      mr: 2,
                      mt: 2,
                      '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                      },
                      '& .MuiDataGrid-row.Mui-selected': {
                        backgroundColor: lighten(theme.palette.primary.light, 0.5), // Couleur de fond pour la ligne sélectionnée
                      },
                      '& .MuiDataGrid-row.Mui-selected:hover': {
                        backgroundColor: lighten(theme.palette.primary.light, 0.5), // Couleur de fond pour la ligne sélectionnée au survol
                      },
                    }}
                  />
                </div>
              </>
            ) : (
              <Card
                variant='outlined'
                sx={{ overflow: 'visible', mt: 2, mx: 2, backgroundColor: theme.palette.grey[100] }}
              >
                <CardContent sx={{ pt: 0, mb: 0 }}>
                  <Typography
                    variant='subtitle1'
                    component='div'
                    align='center'
                    sx={{ pt: 2, pb: 2, color: theme.palette.text.secondary }}
                  >
                    Aucun résultat. <br />
                    <Button
                      variant='outlined'
                      sx={{ mt: 2, color: theme.palette.primary.dark }}
                      onClick={handleOpenAddEmployee}
                    >
                      Cliquez ici pour ajouter votre premier réparateur ! 🛠
                    </Button>
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>

          {selectedEmployee && (
            <Grid container>
              <Grid item xs={8} sx={{ mt: 4, pr: 2 }}>
                <Typography
                  variant='h6'
                  color={theme.palette.text.secondary}
                  sx={{ mb: 2 }}
                  className='styled-alert'
                  style={flexStart}
                >
                  <EngineeringOutlinedIcon color='action' sx={{ pl: 1 }} />
                  <b>&nbsp;&nbsp;Prestations maîtrisées&nbsp;&nbsp;</b> ({selectedEmployee?.prestations?.length})
                </Typography>
                <Typography
                  color={darken(theme.palette.primary.main, 0.1)}
                  sx={{ fontFamily: 'Caveat', fontSize: '1.3rem', mb: 1 }}
                >
                  <b>Les interventions que le réparateur est capable de réaliser</b>
                </Typography>
                <Button
                  variant='contained'
                  sx={{ mb: 2, mt: 1, textTransform: 'none' }}
                  size='small'
                  startIcon={<AddRounded />}
                  onClick={handleAddSpecialtyClick}
                  disabled={!isAdmin}
                >
                  Ajouter une prestation
                </Button>

                {renderPrestations()}
              </Grid>
              <Grid item xs={4} sx={{ mt: 4, pr: 2 }}>
                <Typography
                  variant='h6'
                  color={theme.palette.text.secondary}
                  sx={{ mb: 2 }}
                  className='styled-alert'
                  style={flexStart}
                >
                  <DoNotDisturbAltRoundedIcon color='action' sx={{ pl: 1 }} />
                  <b>&nbsp;&nbsp;Absences / Congés&nbsp;&nbsp;</b> ({selectedEmployee?.absences?.length})
                </Typography>

                <Button
                  variant='contained'
                  sx={{ mt: 2, mb: 1, textTransform: 'none' }}
                  startIcon={<AddRounded />}
                  size='small'
                  onClick={handleAddAbsenceClick}
                  disabled={!isAdmin}
                >
                  Ajouter une absence
                </Button>

                {renderAbsences()}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {renderEmployeeEditDialog()}
      {renderAvailabilityEditDialog()}
      {renderAddAbsenceDialog()}
      {renderAddPrestationDialog()}

      {openAddEmployee && garage && <AddEmployeeDialog open={openAddEmployee} onClose={handleCloseAddEmployee} garage={garage}/>}

      <ConfirmationDialog
        showConfirm
        open={isConfirmationDialogOpen}
        onClose={toggleConfirmationDialogOpen}
        onConfirm={handleDeleteEmployee}
        title={'Suppression du réparateur'}
        message={
          <span>
            Êtes-vous sûr de vouloir supprimer&nbsp;
            <b>
              {selectedEmployee?.firstname} {selectedEmployee?.lastname}
            </b>{' '}
            ?
          </span>
        }
      />
    </Grid>
  );
};

export default Employees;
