import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  darken,
  lighten,
} from '@mui/material';
import type React from 'react';
import { type FunctionComponent, useEffect, useRef, useState } from 'react';
import theme from '../../theme'; // Import du thème personnalisé
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../slices/snackbarSlice';
import { type Garage, GarageService, Logger, SubscriptionState } from '@movalib/movalib-commons';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteCookies } from '../../helpers/Tools';
import CarRepairIcon from '@mui/icons-material/CarRepairRounded';
import TeamIcon from '@mui/icons-material/EngineeringRounded';
import InfoIcon from '@mui/icons-material/InfoRounded';
import ServicesIcon from '@mui/icons-material/BuildRounded';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import SubscriptionIcon from '@mui/icons-material/CardMembershipRounded';
import CarRentalRoundedIcon from '@mui/icons-material/CarRentalRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import MyGarage from './tabContent/MyGarage';
import MyTeam from './tabContent/MyTeam';
import MyServices from './tabContent/MyServices';
import LoanerVehicle from './tabContent/LoanerVehicles';
import CreditCardIcon from '@mui/icons-material/CreditCardRounded';
import PaymentAuthorization from './tabContent/PaymentAuthorization';
import MyOptions from './tabContent/MyOptions';
import { reset } from '../../slices/garageSlice';
import { useFetchSettingsGarages } from '../../query/garage/GarageQuery';
import { useQueryClient } from '@tanstack/react-query';
import { GARAGES_SETTINGS } from '../../query/garage/GarageQueryKeys';
import MySuppliers from './tabContent/MySuppliers';
import DeployedCodeHistory from '../../assets/images/icons/deployed_code_history_grey.svg';
import { CalendarMonth, Language } from '@mui/icons-material';
import AppointmentOnline from './tabContent/AppointementOnline';

export enum SettingsMenu {
  GARAGE = 0,
  TEAM = 1,
  SERVICES = 2,
  ACCOUNT = 3,
  OPTIONS = 4,
  SUBSCRIPTION = 5,
  LOANER_VEHICLE = 6,
  PAYMENT_AUTHORIZATION = 7,
  SUPPLIERS = 8,
  RDV_ONLINE = 9,
}

interface TabPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
}

const drawerWidth = 240;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 11 }}>{children}</Box>}
    </div>
  );
}

interface SettingsProps {
  garageId: string;
}

const Settings: FunctionComponent<SettingsProps> = ({ garageId }) => {
  const dispatch = useDispatch();
  // Récupération des données garage pour l'utilisateur connecté
  const history = useHistory();
  const location = useLocation();
  const [selectedPanel, setSelectedPanel] = useState<SettingsMenu>(SettingsMenu.GARAGE);
  const { data: garage } = useFetchSettingsGarages(garageId, garageId !== undefined);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (selectedPanel === SettingsMenu.SERVICES) {
      refreshGarage();
    }
  }, [selectedPanel]);

  const refreshGarage = () => {
    queryClient.invalidateQueries({ queryKey: [GARAGES_SETTINGS] });
  };

  useEffect(() => {
    return () => {
      if (location.pathname === '/settings') {
        dispatch(reset());
      }
    };
  }, [location.pathname, dispatch]);

  const saveSetting = (request: any) => {
    if (garage) {
      GarageService.updateGarage(garage.id, request).then((response) => {
        if (response.success) {
          dispatch(
            setSnackbar({ open: true, message: response.data ?? 'Modifications enregistrées', severity: 'success' }),
          );
          refreshGarage();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Modifications des données impossible',
              severity: 'error',
            }),
          );
        }
      });
    }
  };

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
  };

  const handleLogoutClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    // Suppression des cookies
    deleteCookies();
    // Suppression des données persistées en localStorage
    localStorage.clear();
    // Redirection vers le login
    history.push('/login');
  };

  const settingsMenuList = () => (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, pt: 10 }}
      role='presentation'
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <Box sx={{ flexGrow: 1 }}>
        {' '}
        {/* Cette Box prendra tout l'espace vertical disponible */}
        <List>
          {/* Mon garage */}
          <ListItem
            key={'menu-1'}
            disablePadding
            sx={{
              backgroundColor:
                selectedPanel === SettingsMenu.GARAGE
                  ? darken(theme.palette.background.paper, 0.1)
                  : lighten(theme.palette.background.paper, 0.6),
            }}
          >
            <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.GARAGE)}>
              <ListItemIcon>
                <CarRepairIcon />
              </ListItemIcon>
              <ListItemText primary={'Mon garage'} />
            </ListItemButton>
          </ListItem>

          {/* Mon équipe */}
          <ListItem
            key={'menu-2'}
            disablePadding
            sx={{
              backgroundColor:
                selectedPanel === SettingsMenu.TEAM
                  ? darken(theme.palette.background.paper, 0.1)
                  : lighten(theme.palette.background.paper, 0.6),
            }}
          >
            <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.TEAM)}>
              <ListItemIcon>
                <TeamIcon />
              </ListItemIcon>
              <ListItemText primary={'Mon équipe'} />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={'menu-3b'}
            disablePadding
            sx={{
              backgroundColor:
                selectedPanel === SettingsMenu.RDV_ONLINE
                  ? darken(theme.palette.background.paper, 0.1)
                  : lighten(theme.palette.background.paper, 0.6),
            }}
          >
            <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.RDV_ONLINE)}>
              <ListItemIcon>
                <Language />
              </ListItemIcon>
              <ListItemText primary={'RDV en ligne'} />
            </ListItemButton>
          </ListItem>

          {/* Mes fournisseurs */}
          <ListItem
            key={'menu-3'}
            disablePadding
            sx={{
              backgroundColor:
                selectedPanel === SettingsMenu.SUPPLIERS
                  ? darken(theme.palette.background.paper, 0.1)
                  : lighten(theme.palette.background.paper, 0.6),
            }}
          >
            <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.SUPPLIERS)}>
              <ListItemIcon>
                <img src={DeployedCodeHistory} alt='DeployedCodeHistory' />
              </ListItemIcon>
              <ListItemText primary={'Mes fournisseurs'} />
            </ListItemButton>
          </ListItem>

          {/* Mes rdv en lgine */}

          {/* Mes services */}
          <ListItem
            key={'menu-4'}
            disablePadding
            sx={{
              backgroundColor:
                selectedPanel === SettingsMenu.SERVICES
                  ? darken(theme.palette.background.paper, 0.1)
                  : lighten(theme.palette.background.paper, 0.6),
            }}
          >
            <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.SERVICES)}>
              <ListItemIcon>
                <ServicesIcon />
              </ListItemIcon>
              <ListItemText primary={'Mes services'} />
            </ListItemButton>
          </ListItem>

          {/* Mon abonnement */}
          {garage?.subscriptions &&
            garage?.subscriptions.filter((s) => s.garageId === garage.id && s.state === SubscriptionState.ACTIVE)
              .length > 0 && (
              <ListItem
                key={'menu-5'}
                disablePadding
                sx={{
                  backgroundColor:
                    selectedPanel === SettingsMenu.SUBSCRIPTION
                      ? darken(theme.palette.background.paper, 0.1)
                      : lighten(theme.palette.background.paper, 0.6),
                }}
              >
                <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.SUBSCRIPTION)}>
                  <ListItemIcon>
                    <SubscriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Mon abonnement'} />
                </ListItemButton>
              </ListItem>
            )}
          
          {/* Mes options */}
          <ListItem
            key={'menu-6'}
            disablePadding
            sx={{
              backgroundColor:
                selectedPanel === SettingsMenu.OPTIONS
                  ? darken(theme.palette.background.paper, 0.1)
                  : lighten(theme.palette.background.paper, 0.6),
            }}
          >
            <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.OPTIONS)}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={'Mes options'} />
            </ListItemButton>
          </ListItem>

          {/* Véhicule de prêt sous "Mes options" */}
          <List component='div' disablePadding>
            {garage?.loanerVehicleActive && (
              <ListItem
                key={'menu-7'}
                disablePadding
                sx={{
                  backgroundColor:
                    selectedPanel === SettingsMenu.LOANER_VEHICLE
                      ? darken(theme.palette.background.paper, 0.1)
                      : lighten(theme.palette.background.paper, 0.6),
                }}
              >
                <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.LOANER_VEHICLE)}>
                  <ListItemIcon sx={{ pl: 2 }}>
                    <CarRentalRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Véhicules de prêt'} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
          {/* Empreinte bancaire sous "Mes options" */}
          <List component='div' disablePadding>
            {garage?.paymentAuthorizationActive && (
              <ListItem
                key={'menu-8'}
                disablePadding
                sx={{
                  backgroundColor:
                    selectedPanel === SettingsMenu.PAYMENT_AUTHORIZATION
                      ? darken(theme.palette.background.paper, 0.1)
                      : lighten(theme.palette.background.paper, 0.6),
                }}
              >
                <ListItemButton onClick={() => setSelectedPanel(SettingsMenu.PAYMENT_AUTHORIZATION)}>
                  <ListItemIcon sx={{ pl: 2 }}>
                    <CreditCardIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Empreinte CB'} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </List>
      </Box>
      <Box>
        {' '}
        {/* Cette Box sera positionnée en bas */}
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={(e) => handleLogoutClick(e)}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={'Déconnexion'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3, ml: 1 }} sx={{ pl: 2, pt: 0 }}>
        <Drawer
          anchor={'left'}
          open={true}
          variant='persistent'
          sx={{
            position: 'fixed',
            zIndex: 10,
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: lighten(theme.palette.background.paper, 0.6),
            },
          }}
        >
          {settingsMenuList()}
        </Drawer>

        <Grid item xs={12} sx={{ ml: `${drawerWidth}px` }}>
          <TabPanel value={selectedPanel} index={SettingsMenu.GARAGE}>
            <MyGarage garage={garage!} refreshGarage={refreshGarage} saveSetting={saveSetting} />
          </TabPanel>

          <TabPanel value={selectedPanel} index={SettingsMenu.TEAM}>
            <MyTeam saveSetting={saveSetting} garage={garage!} />
          </TabPanel>

          <TabPanel value={selectedPanel} index={SettingsMenu.SERVICES}>
            <MyServices garage={garage!} refreshGarage={refreshGarage} />
          </TabPanel>
          <TabPanel value={selectedPanel} index={SettingsMenu.RDV_ONLINE}>
            <AppointmentOnline garage={garage!} refreshGarage={refreshGarage} />
          </TabPanel>
          <TabPanel value={selectedPanel} index={SettingsMenu.SUPPLIERS}>
            <MySuppliers garage={garage!} refreshGarage={refreshGarage} />
          </TabPanel>

          <TabPanel value={selectedPanel} index={SettingsMenu.SUBSCRIPTION}>
            {garage?.subscriptions
              ?.filter((s) => s.garageId === garage.id && s.state === SubscriptionState.ACTIVE)
              .map((subscription) => (
                <>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant='h6' color={theme.palette.text.secondary}>
                      <b>Votre abonnement</b>
                    </Typography>
                    <Typography sx={{ width: '50%', mt: 2.5 }} color={theme.palette.text.secondary}>
                      <b>
                        {subscription.paymentInterval === 'MONTH' ? (
                          <>Mensuel</>
                        ) : subscription.paymentInterval === 'QUARTER' ? (
                          <>Trimestriel</>
                        ) : subscription.paymentInterval === 'YEAR' ? (
                          <>Annuel</>
                        ) : subscription.paymentInterval === 'TWO_YEARS' ? (
                          <>24 Mois</>
                        ) : (
                          <>Non défini</>
                        )}
                      </b>
                      <br />
                    </Typography>
                  </Grid>
                  {process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL && (
                    <Grid item xs={12} sx={{ mt: 3 }}>
                      <Button variant='contained'>
                        <a
                          href={`${process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL}?prefilled_email=${subscription.companyEmail}`}
                          target='_blank'
                          style={{ color: theme.palette.text.primary, textDecoration: 'none' }}
                        >
                          Accès portail facturation MOVALIB
                        </a>
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} sx={{ mt: 3, mr: 2 }}>
                    <Alert icon={<InfoIcon />} sx={{ borderRadius: 20 }} className='styled-alert'>
                      Pour toutes questions relatives à votre abonnement, n’hésitez pas à nous écrire à{' '}
                      <a href='mailto:contact@movalib.com' target='_blank' rel='noreferrer'>
                        compta@movalib.com
                      </a>
                    </Alert>
                  </Grid>
                </>
              ))}
          </TabPanel>

          <TabPanel value={selectedPanel} index={SettingsMenu.ACCOUNT} />

          <TabPanel value={selectedPanel} index={SettingsMenu.OPTIONS}>
            <MyOptions garage={garage!} refreshGarage={refreshGarage} />
          </TabPanel>

          <TabPanel value={selectedPanel} index={SettingsMenu.LOANER_VEHICLE}>
            <LoanerVehicle refreshGarage={refreshGarage} garage={garage!} />
          </TabPanel>

          <TabPanel value={selectedPanel} index={SettingsMenu.PAYMENT_AUTHORIZATION}>
            <PaymentAuthorization refreshGarage={refreshGarage} garage={garage!} />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default Settings;
