import { flexCenter } from "@movalib/movalib-commons/dist/src/helpers/Tools";
import { Grid, Typography, Tooltip, Button, Alert } from "@mui/material";
import UploadDialog from "../../../../dialogs/UploadDialog";
import { isInvalidMobileNumber } from "../../../../helpers/Tools";
import theme from "../../../../theme";
import DoneIcon from '@mui/icons-material/MobileFriendly';
import type { FunctionComponent } from "react";
import { Event, Garage, GarageService as CoreGarageService } from "@movalib/movalib-commons";
import { setSnackbar } from "../../../../slices/snackbarSlice";
import { useDispatch } from "react-redux";
import GarageService from "../../../../services/GarageService";
import { on } from "hammerjs";
interface ClosureEventDialogProps {
    openUploadInvoiceAndSms: boolean,
    handleCancelUploadInvoice: () => void,
    localEvent: Event,
    event: Event,
    garage: Garage,
    setLoading: (loading: boolean) => void,
    purgeLocalState: () => void,
    refreshSelectedVehicle: () => void,
    onClose: (refresh: boolean) => void,
    closureDialogOnlySMS: boolean,
    refreshEvent: () => void,
    onRefresh?: () => void;}

const ClosureEventDialog : FunctionComponent<ClosureEventDialogProps> = ({
    openUploadInvoiceAndSms,
    handleCancelUploadInvoice,
    setLoading,
    event,
    purgeLocalState,
    refreshSelectedVehicle,
    garage,
    closureDialogOnlySMS= false,
    onClose,
    refreshEvent,
    onRefresh,
    localEvent}
) => {
const dispatch = useDispatch();

  const handleSendVehicleAvailable = () => {
    if (garage.id && event.id)
    {
      setLoading(true);

      // Téléchargement du document
      CoreGarageService.sendAppointmentVehicleAvailable(garage.id, event.id)
        .then((response) => {
          if (response.success)
          {
            // Affichage notification utilisateur
            dispatch(
              setSnackbar({
                open: true,
                message: response.data ?? 'Le SMS de fin de rendez-vous a bien été envoyé',
                severity: 'success',
              }),
            );

            // On recharge l'event local et on appelle le callback de refresh des events
            refreshEvent();
            if (onRefresh) onRefresh();
            if (closureDialogOnlySMS) {
              onClose(true);
            }
          } else
          {
            dispatch(
              setSnackbar({
                open: true,
                message: response.error ?? "Erreur lors de l'envoi du SMS",
                severity: 'error',
              }),
            );
          }
        })
        .catch((error) => {
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const closeEvent = (invoice: File | null) => {
    if (event && garage.id)
    {
      // Utilisation d'un formData pour permettre le trasnfert de fichier vers l'API
      const formData = new FormData();

      // Ajouter la facture à FormData si présente
      if (invoice)
      {
        formData.append('invoice', invoice);
        // Ajouter l'identifiant de l'événement en référence au document
        formData.append('reference', event.id);
      }

      GarageService.closeGarageEvent(dispatch, garage.id, event.id, formData)
        .then((response) => {
          purgeLocalState();

          // Fermeture de la boite de dialogue (avec refresh des events)
          onClose(true);

          //Affichage notification utilisateur
          dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));

          // On recharge le véhicule
          refreshSelectedVehicle();
        })
        .catch((error) => {
          console.error(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        });
    }
  };

  const handleConfirmUploadInvoice = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0)
    {
      closeEvent(event.target.files[0]);
    } else
    {
      closeEvent(null);
    }
  };
  return (
    <UploadDialog
      open={openUploadInvoiceAndSms}
      //onClose={handleCloseUploadInvoice}
      onConfirm={closureDialogOnlySMS ? undefined : handleConfirmUploadInvoice}
      onCancel={handleCancelUploadInvoice}
      title={closureDialogOnlySMS ? 'SMS fin de rendez-vous' : 'Clôture du rendez-vous'}
      yesLabel={<>JOINDRE&nbsp;LA&nbsp;FACTURE</>}
      //noLabel={<>CLÔTURER&nbsp;<b>SANS</b>&nbsp;FACTURE</>}
      message={
        <>
          <Grid container style={flexCenter}>
            <Typography variant='h6' sx={{ mt: 2, textAlign: 'center' }} style={flexCenter}>
              <DoneIcon style={{ fontSize: 34, marginRight: 14, color: theme.palette.primary.main }} />
              Vous pouvez prévenir le client par SMS que son véhicule est prêt si besoin 😉 !
            </Typography>
           <Tooltip
              title={
                isInvalidMobileNumber(localEvent.customer?.phoneNumber) &&
                isInvalidMobileNumber(localEvent.customer?.companyPhoneNumber)
                  ? 'Numéro de téléphone invalide'
                  : ''
              }
              placement='top'
            >
              <span style={{ ...flexCenter, width: '100%' }}>
                <Button
                  component='span'
                  color='primary'
                  variant='outlined'
                  sx={{ mr: 2, mt: 3, width: '50%', color: theme.palette.primary.dark }}
                  onClick={handleSendVehicleAvailable}
                  disabled={
                    localEvent.vehicleAvailableNotified ||
                    (isInvalidMobileNumber(localEvent.customer?.phoneNumber) &&
                      isInvalidMobileNumber(localEvent.customer?.companyPhoneNumber))
                  }
                >
                  {!localEvent.vehicleAvailableNotified ? <b>Envoyer SMS fin de rdv</b> : <b>SMS envoyé</b>}
                </Button>
              </span>
            </Tooltip>
          </Grid>
         {!closureDialogOnlySMS && <Alert style={flexCenter} severity='success' sx={{ mt: 4 }}>
            <Typography variant='body1' style={{ textAlign: 'center' }}>
              Merci de&nbsp;<b>joindre la facture</b>&nbsp; pour clôturer le rendez-vous, <br />
              elle sera automatiquement transmise au client.
            </Typography>
          </Alert>}
        </>
      }
    />
  );
};
export default ClosureEventDialog;
