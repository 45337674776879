import type { Garage } from "@movalib/movalib-commons";
import { Grid } from "@mui/material";
import type { FunctionComponent } from "react";
import SuppliersTable from "./SuppliersTable";

interface MySuppliersProps {
    refreshGarage: () => void;
    garage: Garage;
}

const MySuppliers: FunctionComponent<MySuppliersProps> = ({
    refreshGarage,
    garage,
}) => {
    return (<>
        <Grid container sx={{ pb: 2, pr: 4 }}>
            {/* Section Grossistes */}
            {garage && <SuppliersTable garage={garage!} refreshGarage={refreshGarage} />}
        </Grid>

    </>);
}
export default MySuppliers;