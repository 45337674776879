import { GarageService as CoreGarageService, type Garage } from '@movalib/movalib-commons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { type View, Views } from 'react-big-calendar';
import { useDispatch } from 'react-redux';
import type { Dispatch } from 'redux';
import { addLunchTimeUnavailability } from '../../helpers/events/addLunchTimeUnavailability';
import EventService from '../../services/EventService';
import GarageService from '../../services/GarageService';
import { setSnackbar } from '../../slices/snackbarSlice';
import { EVENTS } from './EventQueryKeys';

export type UseGenerateEventsProps = {
  garageId: Garage['id'];
  startDate: string;
  endDate: string;
};
export const useGenerateEvents = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (queryProps: UseGenerateEventsProps) => {
      await EventService.generateEvents(queryProps);
      await queryClient.invalidateQueries({ queryKey: [EVENTS, queryProps.garageId] });
    },
  });
};

type UseFetchEventList = {
  view: View;
  dispatch: Dispatch;
  garage: Garage;
  schedules: Garage['schedules'];
  startPeriod: Date;
  endPeriod: Date;
};
// only use in calendar for feed agenda
export const useFetchEventsList = ({
  view,
  dispatch,
  garage,
  schedules,
  startPeriod,
  endPeriod,
}: UseFetchEventList) =>
  useQuery({
    queryKey: [EVENTS, garage?.id ?? '', view, startPeriod, endPeriod],
    initialData: [],
    refetchInterval: 300000,
    queryFn: async () => {
      if (!garage?.id || !startPeriod || !endPeriod || garage.id === '') {
        return null;
      }
      const events = await GarageService.getCalendarEvents(dispatch, garage.id, startPeriod, endPeriod);
      if (!events) {
        return [];
      }

      if (view !== Views.MONTH) {
        return addLunchTimeUnavailability({ events, schedules, garageId: garage.id, currentDay: startPeriod, garageTimeZone: garage.timezone });
      }
      return events;
    },
    enabled: !!garage?.id
  });

type UseEditGarageEvent = {
  garageId: Garage['id'];
  eventId: string;
  req: Record<string, unknown>;
};

export const useEditGarageEvent = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async (event: UseEditGarageEvent) => {
      const { garageId, eventId, req } = event;
      const response = await CoreGarageService.updateGarageEvent(garageId, eventId, req);
      if (response.error) {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error ?? 'Mise à jour du rendez-vous impossible',
            severity: 'error',
          }),
        );
        return response;
      }

      await queryClient.invalidateQueries({ queryKey: [EVENTS] });
      dispatch(
        setSnackbar({
          open: true,
          message: response.data ?? 'Votre rendez-vous a bien été mis à jour',
          severity: 'success',
        }),
      );
      return response;
    },
  });
};

export const useAcceptQuoteEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ eventId, garageId }: Record<string, string>) => {
      await GarageService.scheduleGarageEvent(garageId, eventId);
      await queryClient.invalidateQueries({ queryKey: [EVENTS] });
    },
  });
};
