import { GarageService, Logger, MovaDialog, MovaFormField, Supplier, validateEmail, validateField } from '@movalib/movalib-commons';
import { Alert, Box, Button, FormHelperText, Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import { useState, type FC, FormEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../slices/snackbarSlice';
import InfoIcon from '@mui/icons-material/InfoRounded';

interface AddSupplierDialogProps {
    open: boolean,
    garageId: string,
    showHelp?: boolean,
    onClose?: (refresh:boolean) => void;
    supplier?: Supplier
}

type SupplierForm = {
    name: MovaFormField,
    email: MovaFormField,
    phoneNumber: MovaFormField,
    orderPortalUrl: MovaFormField,
    customerId: MovaFormField,
  }

const AddOrEditSupplierDialog: FC<AddSupplierDialogProps> = ({ open, garageId, showHelp = false, onClose, supplier }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [form, setForm] = useState<SupplierForm>({
        name: { value: supplier?.name ?? '', error: '', isValid: true },
        email: { value: supplier?.email ?? '', error: '', isValid: true },
        phoneNumber: { value: supplier?.phoneNumber ?? '', error: '', isValid: true },
        orderPortalUrl: { value: supplier?.orderPortalUrl ?? '', error: '', isValid: true },
        customerId: { value: supplier?.customerId ?? '', error: '', isValid: true }
      });
    const [isFormValid, setIsFormValid] = useState(false);
    const dispatch = useDispatch();

    const purgeLocalState = () => {
        setIsFormValid(false);
    }

    const handleOnClose = (refresh:boolean) => {
        purgeLocalState();
        if(onClose){
            onClose(refresh);
        }
    }

    const validateForm = () => {

        let newForm: SupplierForm = { ...form };
    
        // Validator pour les champs obligatoires
        newForm.name = validateField(form.name, value => !!value, 'Champ obligatoire');

        // Validator pour l'email
        if(form.email.value.length > 0) {
            newForm.email = validateField(form.email, validateEmail, 'Adresse email invalide');
        }
    
        setForm(newForm);
        let isValid = Boolean(newForm.name.isValid && newForm.email.isValid);

        setIsFormValid(isValid);
    
        return isValid;
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            if(validateForm() && garageId) {
            
                // Ajout du grossiste
                const req = {
                    name: form.name.value,
                    email: form.email.value,
                    phoneNumber: form.phoneNumber.value,
                    orderPortalUrl: form.orderPortalUrl.value,
                    customerId: form.customerId.value,
                };
                if(supplier){
                    GarageService.updateGarageSupplier(garageId, supplier.id, req)
                    .then(response => {
                        if(response.success){
                            dispatch(setSnackbar({ open: true, message: "Fournisseur modifié avec succès", severity: 'success' }));
                            // On ferme la dialog box avec demande de refresh
                            handleOnClose(true);
                        }else{
                            dispatch(setSnackbar({ open: true, message: response.error ?? "Modification du fournisseur impossible", severity: 'error' }));
                        }
                    });
                } else {
                    GarageService.createGarageSupplier(garageId, req)
                    .then(response => {
                        if(response.success){
                            dispatch(setSnackbar({ open: true, message: "Fournisseur ajouté avec succès", severity: 'success' }));
                            // On ferme la dialog box avec demande de refresh
                            handleOnClose(true);
                        }else{
                            dispatch(setSnackbar({ open: true, message: response.error ?? "Ajout du fournisseur impossible", severity: 'error' }));
                        }
                    });
                };
               
            }

        }catch (error){
            Logger.error('Error occurred during submission:', error);
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const fieldName: string = e.target.name;
        let fieldValue: string = e.target.value;
        if (fieldName === 'phoneNumber') {
            fieldValue = fieldValue.replace(/\D/g, '');
            if (fieldValue.length > 10) {
              fieldValue = fieldValue.slice(0, 10); 
            }
          }
        
          const isValid = fieldName === 'phoneNumber' ? fieldValue.length === 10 : true;
        
          const newField: MovaFormField = {
            [fieldName]: {
              value: fieldValue,
              isValid: isValid,            },
          };
        
          setForm({ ...form, ...newField });
      }     

    return (
        <>
            <MovaDialog fullScreen={isMobile} open={open} onClose={() => handleOnClose(false)} leafImageColor="green" 
                closable
                title={`${supplier?.id ? 'MODIFIER' : 'AJOUTER'} UN FOURNISSEUR`} 
                actions={
                    <Button onClick={handleSubmit} 
                        color="primary" sx={{  width:'90%' }} 
                        disabled={Boolean(form.name.value.length === 0)} 
                        variant='contained'>
                        {supplier?.id ? 'Modifier' : 'Ajouter'} ce fournisseur
                    </Button>
                }
            >
                <Grid container>

                    {showHelp && <Alert severity="success" sx={{ mb: 2, borderRadius: 20 }} icon={<InfoIcon />}>
                        Ce fournisseur sera disponible sur le détail d'un rendez-vous, au niveau de la colonne "Commande" 😉
                    </Alert>}

                    <Grid item md={6} xs={12} sx={{paddingRight: {md: 1}}}>
                        <TextField
                            margin="normal"
                            required
                            autoFocus
                            fullWidth
                            id="name"
                            label="Nom"
                            name="name"                    
                            onChange={e => handleInputChange(e)}
                            value={form.name.value}
                            error={Boolean(form.name.error)}
                            helperText={form.name.error}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            margin="normal"
                            type='number'
                            fullWidth
                            id="phoneNumber"
                            label="Numéro de téléphone"
                            name="phoneNumber"                    
                            onChange={e => handleInputChange(e)}
                            value={form.phoneNumber.value}
                            error={Boolean(form.phoneNumber.error)}
                            helperText={form.phoneNumber.error}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Adresse email"
                            name="email"
                            autoComplete="email"                      
                            onChange={e => handleInputChange(e)}
                            value={form.email.value}
                            error={Boolean(form.email.error)}
                            helperText={form.email.error}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            type='text'
                            fullWidth
                            id="customerId"
                            label="Numéro client"
                            name="customerId"                    
                            onChange={e => handleInputChange(e)}
                            value={form.customerId.value}
                            error={Boolean(form.customerId.error)}
                            helperText={form.customerId.error}
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            type='url'
                            fullWidth
                            id="orderPortalUrl"
                            label="Site web"
                            name="orderPortalUrl"
                            placeholder='https://www.example.com'                  
                            onChange={e => handleInputChange(e)}
                            value={form.orderPortalUrl.value}
                            aria-describedby="outlined-orderPortalUrl"
                            error={Boolean(form.orderPortalUrl.error)}
                            helperText={form.orderPortalUrl.error}
                        />
                         <FormHelperText id="outlined-orderPortalUrl">N'oubliez pas de mettre htpp:// ou https:// au début</FormHelperText>
                    </Grid>
                </Grid>

            </MovaDialog>
        </>
    );
}

export default AddOrEditSupplierDialog;