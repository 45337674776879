import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import React, { CSSProperties, FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import GreenLeafImage from '../../assets/images/leaf_green_large.png';
import PinkLeafImage from '../../assets/images/leaf_pink_large.png';
import {
  ConfirmationDialog,
  DateFormatTypes,
  Garage,
  GarageService,
  Logger,
  MovaAppType,
  RegistrationState,
  User,
  UserService,
  formatDateByTimezone,
} from '@movalib/movalib-commons';
import { COOKIE_INDIVIDUAL_TOKEN, readCookie } from '@movalib/movalib-commons/dist/src/helpers/CookieUtils';
import {
  Alert,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  darken,
  useTheme,
} from '@mui/material';
import SignUpHeader from '../../components/registration/SignUpHeader';
import { createCookie, deleteCookie, deleteCookies, flexEnd, flexLeftRow } from '../../helpers/Tools';
import { useHistory, useLocation } from 'react-router-dom';
import { COOKIE_PRO_TOKEN, COOKIE_SELECTED_GARAGE_ID } from '../../helpers/Constants';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../slices/snackbarSlice';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import { reset, setGarageData } from '../../slices/garageSlice';
import { useQueryClient } from '@tanstack/react-query';

const Registrations: FunctionComponent = () => {
  const queryClient = useQueryClient();
  const isAuthenticated: boolean = Boolean(readCookie(COOKIE_PRO_TOKEN));
  const [connectedUser, setConnectedUser] = useState<User | undefined>();
  const [registeredGarages, setRegisteredGarages] = useState<Garage[] | []>();
  // Pour la popup de confirmation d'activation d'un garage
  const [openConfirmActivate, setOpenConfirmActivate] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const [selectedGarage, setSelectedGarage] = useState<Garage>();

  // Chargement initial de la page
  useEffect(() => {
    if (isAuthenticated) {
      // On contrôle que l'utilisateur dispose du rôle adéquat
      UserService.getCurrentUser(MovaAppType.GARAGE).then((response) => {
        if (response.success) {
          setConnectedUser(response.data);
          refreshRegisteredGarages();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Chargement de votre compte impossible, contactez l'équipe Movalib",
              severity: 'error',
            }),
          );
        }
      });
    }
  }, [location.search]);

  // Chargement initial de la page
  useEffect(() => {
    refreshRegisteredGarages();
  }, [connectedUser]);

  const refreshRegisteredGarages = () => {
    if (connectedUser) {
      // Chargement des inscriptions de garage rattachées au profil
      UserService.getSalesGarages(connectedUser.id).then((response) => {
        if (response.success) {
          Logger.info(response.data);
          setRegisteredGarages(response.data);
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Chargement des inscriptions impossible, contactez l'équipe Movalib",
              severity: 'error',
            }),
          );
        }
      });
    }
  };

  const isSignupAuthorized = (user: User | undefined) => {
    return user && (User.isSales(user) || User.isSuperAdmin(user));
  };

  const handlePageClick = (page: string) => {
    if (page) {
      // Cas particulier du Logout
      switch (page) {
        case 'PRICING':
          deleteCookie(COOKIE_SELECTED_GARAGE_ID);
          // Redirection vers le formulaire d'inscription
          history.push('/pricing');
          break;
        case 'LOGOUT':
          reset();
          dispatch(setGarageData(null));
          queryClient.clear();
          // Suppression des cookies
          deleteCookies();
          // Suppression de l'utilisateur connecté
          setConnectedUser(undefined);
          // Redirection vers le login
          history.push('/signup');
          break;

        case 'REGISTRATIONS':
          refreshRegisteredGarages();
          break;

        case 'SIGNUP':
          deleteCookie(COOKIE_SELECTED_GARAGE_ID);
          // Redirection vers le formulaire d'inscription
          history.push('/signup');
          break;
      }
    }
  };

  const getRegistrationStateColor = (registrationState: RegistrationState | undefined): string => {
    if (registrationState) {
      switch (registrationState) {
        case RegistrationState.NEW:
          return theme.palette.grey[400];
        case RegistrationState.COMPLETED:
          return theme.palette.secondary.main;
        case RegistrationState.VALIDATED:
          return theme.palette.primary.main;
        case RegistrationState.ACTIVATED:
          return '#FFFFFF';
        case RegistrationState.REJECTED:
          return '#D5687A';
        default:
          return theme.palette.grey[200];
      }
    } else return theme.palette.grey[200];
  };

  const getRegistrationStateLabel = (registrationState: RegistrationState | undefined): ReactNode => {
    if (registrationState) {
      switch (registrationState) {
        case RegistrationState.NEW:
          return 'Inscription à finaliser ...';
        case RegistrationState.COMPLETED:
          return "À mettre en service par l'équipe Movalib.";
        case RegistrationState.VALIDATED:
          return 'Inscription validée ! Clique ici pour activer le garage.';
        case RegistrationState.ACTIVATED:
          return <>🏁&nbsp;&nbsp;Garage actif&nbsp;&nbsp;🏁</>;
        case RegistrationState.REJECTED:
          return 'Client churn ou inscription invalide.';
      }
    } else return 'Non défini';
  };

  const handleClick = (event: React.MouseEvent<unknown>, garage: Garage) => {
    if (
      garage &&
      (garage.registrationState === RegistrationState.NEW || garage.registrationState === RegistrationState.COMPLETED)
    ) {
      // Redirection vers la page de signup pré-chargée avec le garage sélectionnée
      //localStorage.setItem('selectedGarage', JSON.stringify(garage));
      // Inscription de l'identifiant du garage dans un Cookie
      createCookie(COOKIE_SELECTED_GARAGE_ID, garage.id);
      history.push('/signup');
    } else if (garage.registrationState === RegistrationState.VALIDATED) {
      setSelectedGarage(garage);
      setOpenConfirmActivate(true);
    }
  };

  const handleConfirmActivate = () => {
    if (selectedGarage && selectedGarage.registrationState === RegistrationState.VALIDATED) {
      // Activation du garage
      GarageService.activateGarage(selectedGarage.id).then((response) => {
        if (response.success) {
          dispatch(
            setSnackbar({
              open: true,
              message:
                response.data ??
                "Le garage a été activé avec succès, le responsable va recevoir un mail d'ici quelques instants 💪",
              severity: 'success',
            }),
          );
          // Fermeture fenêtre de confimation
          setOpenConfirmActivate(false);
          // On refresh la page
          refreshRegisteredGarages();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Chargement des inscriptions impossible, contactez l'équipe Movalib",
              severity: 'error',
            }),
          );
        }
      });
    }
  };

  const handleCloseConfirmActivate = () => {
    setOpenConfirmActivate(false);
  };

  const handleSignup = () => {
    deleteCookie(COOKIE_SELECTED_GARAGE_ID);
    // Redirection vers le formulaire d'inscription
    history.push('/signup');
  };

  return (
    <>
      <div style={flexCenter}>
        <img
          src={GreenLeafImage}
          style={{
            position: 'fixed',
            float: 'left',
            width: '250px',
            height: '400px',
            top: '-20%',
            left: '0%',
            opacity: '0.3',
            display: connectedUser ? 'block' : 'none',
            zIndex: -8,
          }}
          alt='Feuille Verte Movalib'
        />

        <Container component='main' maxWidth='xl'>
          <CssBaseline />

          {connectedUser && isSignupAuthorized(connectedUser) && (
            <>
              <SignUpHeader
                title="Client(s) en cours d'inscription"
                connectedUser={connectedUser}
                onPageClick={handlePageClick}
              />

              <Grid container>
                <Grid item xs={12} style={flexLeftRow}>
                  <Button
                    variant='contained'
                    startIcon={<AddRoundedIcon />}
                    color='primary'
                    onClick={handleSignup}
                    style={flexCenter}
                    sx={{
                      mt: 12,
                      mb: 4,
                      textTransform: 'none',
                    }}
                  >
                    Nouvelle inscription
                  </Button>
                  <Button
                    sx={{ mt: 8, ml: 2, color: theme.palette.primary.dark }}
                    onClick={() => history.push('/registrations?key=' + Date.now())}
                  >
                    <RefreshIcon sx={{ fontSize: '1.5rem', mr: 1 }} /> Actualiser
                  </Button>
                </Grid>
              </Grid>

              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: '70vh' }}>
                  <Table stickyHeader aria-label='sticky table' size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          key={0}
                          align='left'
                          style={{
                            width: 150,
                          }}
                        >
                          <Typography>Nom du garage</Typography>
                        </TableCell>

                        <TableCell
                          key={1}
                          align='left'
                          style={{
                            width: 20,
                          }}
                        >
                          <Typography>Date d'inscription</Typography>
                        </TableCell>
                        <TableCell
                          key={2}
                          align='left'
                          style={{
                            width: 150,
                          }}
                        >
                          <Typography>Statut</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {registeredGarages && registeredGarages.length === 0 && (
                        <TableRow key={0}>
                          <TableCell colSpan={3} align='center' sx={{ color: theme.palette.text.secondary, p: 4 }}>
                            AUCUN RESULTAT
                          </TableCell>
                        </TableRow>
                      )}
                      {registeredGarages
                        ?.sort(
                          (b, a) =>
                            new Date(a.creationDate ? a.creationDate : '').getTime() -
                            new Date(b.creationDate ? b.creationDate : '').getTime(),
                        )
                        //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((garage) => (
                          <TableRow
                            key={garage.id}
                            hover
                            role='checkbox'
                            tabIndex={-1}
                            sx={{
                              cursor:
                                garage.registrationState !== RegistrationState.REJECTED &&
                                garage.registrationState !== RegistrationState.ACTIVATED
                                  ? 'pointer'
                                  : 'standard',
                            }}
                            onClick={(event) => handleClick(event, garage)}
                          >
                            <TableCell align='left'>
                              <Typography>
                                <strong>{garage.name}</strong>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {formatDateByTimezone(garage.creationDate, garage.timezone, DateFormatTypes.SHORT_FORMAT_DATE)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Alert
                                severity='info'
                                variant='filled'
                                icon={<></>}
                                style={flexCenter}
                                sx={{
                                  width: '100%',
                                  textTransform: 'none',
                                  textAlign: 'center',
                                  color:
                                    garage.registrationState === RegistrationState.ACTIVATED
                                      ? theme.palette.text.secondary
                                      : 'white',
                                  backgroundColor: darken(getRegistrationStateColor(garage.registrationState), 0.1),
                                }}
                              >
                                <b>{getRegistrationStateLabel(garage.registrationState)}</b>
                                <br />
                              </Alert>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          )}
        </Container>

        <img
          src={PinkLeafImage}
          style={{
            position: 'fixed',
            float: 'right',
            width: '250px',
            height: '400px',
            bottom: '-20%',
            right: '0%',
            opacity: '0.3',
            display: connectedUser ? 'block' : 'none',
            zIndex: '-10',
          }}
          alt='Feuille Rose Movalib'
        />
      </div>

      {openConfirmActivate && (
        <ConfirmationDialog
          open={openConfirmActivate}
          onClose={handleCloseConfirmActivate}
          onConfirm={handleConfirmActivate}
          message={
            <>
              <Typography>
                <b>Tu confirmes l'activation du garage ?</b>
              </Typography>
              <Typography variant='body2'>
                <br />
                Ton client va automatiquement recevoir son email d'accès à Movalib Pro 😉.
              </Typography>
            </>
          }
        />
      )}
    </>
  );
};

export default Registrations;
