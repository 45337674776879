import {
  DialogTitle,
  Tab,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  type ToggleButtonProps,
  Tooltip,
  darken,
  lighten,
  styled,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';
import { getEventTypeColor } from './Tools';
import { EventType } from '@movalib/movalib-commons';

interface StyledProps {
  eventType: EventType;
}

export const StyledEventTypeToggleButtonGroup = styled(ToggleButtonGroup)<StyledProps>(({ theme, eventType }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    '&.Mui-disabled': {
      border: 0,
      backgroundColor: theme.palette.grey[100],
    },
    '&.Mui-selected': {
      background: getEventTypeColor(eventType, theme),
      color: eventType === EventType.NOTE ? 'white' : 'default',
      border: eventType === EventType.PENDING_APPOINTMENT ? '2px solid #00BF08' : 'none',
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
      //border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:hover': {
      //backgroundColor: lighten(getEventTypeColor(eventType, theme), 0.3), // Modifier la couleur de fond au survol
    },
    '&.Mui-selected:hover': {
      // backgroundColor: theme.palette.primary.light, // Modifier la couleur de fond au survol
    },
  },
}));

// Style personnalisé pour la Tooltip des RDV agenda
export const CustomTooltip = styled(Tooltip)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: '1rem',
    border: '1px solid #dadde9',
    position: 'relative',
  },
  '& .MuiTooltip-arrow': {
    color: '#f5f5f9',
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '0.9rem',
  '&.Mui-selected': {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  paddingRight: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.grey[200],
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}:hover`]: {
    backgroundColor: lighten(theme.palette.primary.light, 0.5), // Modifiez ceci selon la couleur souhaitée
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    //backgroundColor: darken(theme.palette.primary.main,0.4),
    color: darken(theme.palette.primary.main, 0.4),
    fontWeight: 'bold',
    borderRadius: 20,
    border: 0,
    //borderLeft: `5px solid ${theme.palette.primary.main}`,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    borderRight: '100px solid transparent',
    fontSize: '0.9rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledGroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
}));

export const StyledGroupItems = styled('ul')({
  padding: 0,
});
