import { APIMethod } from "./Enums";
import { isEmpty, readCookie } from "./Tools";
import { setSnackbar } from "../slices/snackbarSlice";
import { AnyAction, Dispatch } from "redux";
import Cookies from "js-cookie";
import { COOKIE_PRO_TOKEN } from "./Constants";
import Logger from "./Logger";
import { deleteCookie } from "@movalib/movalib-commons";

export type APIRequest = {
    url: string,
    method: APIMethod,
    body?: string | FormData
}

function handleError(error: Error, dispatch?: Dispatch<AnyAction> | null) {

    let msg = error.message.includes('fetch') ? "Connexion impossible" : error.message;

    if(dispatch && dispatch !== null){
        dispatch(setSnackbar({ open: true,  message: msg, severity: 'error' }))
    }

   throw msg;  // Rethrow the error after handling it
}

function handleResponse(response: Response): Promise<any> {
    const contentType = response.headers.get("content-type");
    const isJson = contentType && contentType.includes("application/json");

    const dataPromise = isJson ? response.json() : response.text();

    return dataPromise.then(data => {
        
        if (!response.ok) {

            Logger.error(data);
            // custom code for expiration token
                if (data.code === '101' || data.code === '102' || data.code === '100') {
                    localStorage.clear();
                    deleteCookie(COOKIE_PRO_TOKEN);
                    return window.location.href = '/disconnected/';
                }

            switch(response.status){
                case 403:
                    throw new Error('Accès non autorisé (403)');
                case 404:
                    throw new Error('La ressource demandée est introuvable (404)');
                case 500:
                    throw new Error('Une erreur interne du serveur est survenue (500)');
                default:
                    throw new Error((typeof data === 'string') ? data : `Une erreur est survenue (${response.status})`);
            }
        }
        
        return data;
    });
}


/**
 * ATTENTION : cela signifie que toutes les requêtes du Front seront de type "preflight"
 * Il faut penser à configurer l'API pour autoriser les headers
 * @param options 
 * @param dispatch 
 * @returns 
 */
export const request = (options:APIRequest, dispatch?: Dispatch<AnyAction> | null) => {

    const headers = new Headers();

    // Si le corps est de type FormData, il ne faut pas définir le content-type manuellement (le navigateur prend le relai est injecte un boundary (délimiteur)
    if (!(options.body instanceof FormData)) {
        headers.append('Content-Type', 'application/json');
    }

    // Récupération du TOKEN depuis les cookies et intégration au header
    if(readCookie(COOKIE_PRO_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + readCookie(COOKIE_PRO_TOKEN))
    }

    // On intègre les headers aux options
    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    Logger.info(options);

      // Appel API
    return fetch(options.url, options)
        .then((response) => {
            return handleResponse(response)}
        )
        .then(data => data)
        .catch(error => {
            Logger.error('There has been a problem with your fetch operation : ', error.message);
            handleError(error, dispatch)
        });
}