
import { type Event, EventState, EventType, formatFrenchVehiclePlate, Garage, } from '@movalib/movalib-commons';
import { Card, CardHeader, Typography, CardContent, Box, Collapse , Avatar,} from '@mui/material';
import { isSameDay, isWithinInterval, startOfWeek, endOfWeek, format } from 'date-fns';
import { useState } from 'react';
import { Views } from 'react-big-calendar';
import type { DragAction, DragDirection } from 'react-big-calendar/lib/addons/dragAndDrop';
import { sortEventsAsc } from '../../../helpers/Tools';
import theme from '../../../theme';
import MyCalendarEventMemo from './MyCalendarEventMemo';
import { ExpandMore, MEMO_EVENTS_SIZE_UNEXPANDED } from './MyCalendarEventMemoList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function dispatcherTemplate(events: Event[], view: string, currentDate: Date, inputSearch: string, openDrawer: boolean,
   expandMemoEvents: () => void,
    handleSelectEvent: (event: Event) => void,
     dragStart: (({ event, action, direction, fromDispatcher, }: { event: Event; action: DragAction; direction: DragDirection; fromDispatcher?: boolean; }) => void) | undefined, 
     garage: Garage) {
    return () => {
      const searchEvents = (events: Event[], searchString: string) => {
        if (!searchString) return events;
  
        const normalizedSearch = searchString.toLowerCase();
  
        return events.filter(({ title, customer, vehicle }) => [
          title,
          customer?.firstname,
          customer?.lastname,
          customer?.phoneNumber,
          vehicle?.plate,
          vehicle?.brand,
          formatFrenchVehiclePlate(vehicle?.plate),
        ].some((field) => field?.toLowerCase().includes(normalizedSearch))
        );
      };
      const excludedStates = [EventState.CANCELLED, EventState.NEW, EventState.DONE];
      let filteredEvents = !events?.length
        ? []
        : events.filter((event) => {
          return (
            event.type === EventType.APPOINTMENT && // Vérifier que c'est un RDV
            !excludedStates.includes(event.state) && // Exclure les états annulé/nouveau
            event.resourceId === null && // Vérifier que resourceId est null
            ((view === Views.DAY && isSameDay(event.start as Date, currentDate)) || // Mode "Jour"
              (view === Views.WORK_WEEK &&
                isWithinInterval(event.start as Date, {
                  // Mode "Semaine de travail"
                  start: startOfWeek(currentDate, { weekStartsOn: 1 }), // Lundi
                  end: endOfWeek(currentDate, { weekStartsOn: 1 }), // Dimanche
                })) ||
              (view !== Views.DAY && view !== Views.WORK_WEEK)) // Cas général (si aucun filtrage spécifique)
          );
        }).sort((a, b) => {return (a.start as Date)?.getTime() - (b.start as Date)?.getTime();});
      filteredEvents = searchEvents(filteredEvents, inputSearch);
      const groupedEvents: { [key: string]: Event[]; } = filteredEvents.reduce((acc, event) => {
        // Formater la date en 'YYYY-MM-DD' pour regrouper par jour
        const eventDate = format(event.start as Date, "dd/MM/yyyy");
  
        // Si la date n'existe pas encore dans l'accumulateur, l'initialiser
        if (!Array.isArray(acc[eventDate]))
        {
          acc[eventDate] = [];
        }
  
        // Ajouter l'événement à la date correspondante
        acc[eventDate].push(event);
  
        return acc;
      }, {} as { [key: string]: Event[]; });
      const [openSections, setOpenSections] = useState<{ [key: string]: boolean; }>(
        Object.keys(groupedEvents).reduce((acc, date) => {
          acc[date] = groupedEvents[date].length <= MEMO_EVENTS_SIZE_UNEXPANDED; // Ouvre par défaut si <= 2 événements
          return acc;
        }, {} as { [key: string]: boolean; }));
  
      // Fonction pour basculer l'affichage d'une date
      const toggleSection = (date: string) => {
        setOpenSections((prev) => ({ ...prev, [date]: !prev[date] }));
      };
      return (
        <Card variant='outlined' sx={{ width: '100%', border: 'none' }}>
          {!openDrawer && <CardHeader
            avatar={<Avatar
              onClick={()=> expandMemoEvents()}
              variant='square'
              sx={{
                backgroundColor: 'transparent',
                width: openDrawer ? '50px' : '45px',
                cursor: openDrawer ? '' : 'pointer',
              }}
            >
              <Typography sx={{ color: 'gray', fontWeight: 600, fontSize: 35 }}>{filteredEvents.length}</Typography>
            </Avatar>}
  
            sx={{
              paddingLeft: !openDrawer ? 0 : 'auto',
              color: '#464646',
              borderTop: '7px solid gray',
              border: !openDrawer ? '7px solid gray' : '',
              padding: openDrawer ? 2 : 0,
              mx: openDrawer ? 0 : 1,
              px: '0 !important',
              height: openDrawer ? 'auto' : '45px',
              width: openDrawer ? '100%' : '45px',
              borderRadius: openDrawer ? '12px' : '40px',
            }} />}
          <CardContent sx={{ p: 0, display: !openDrawer ? 'none' : 'block' }}>
  
            {view === Views.WORK_WEEK && Object.entries(groupedEvents).sort(
              ([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime()).map(([date, events]) => {
                const isExpanded = openSections[date] || events.length <= 2;
                const showExpandButton = events.length > 2; // Afficher le bouton uniquement si plus de 2 événements
  
                return (
                  <Box key={date} sx={{ mb: 2 }}>
                    {/* Titre + Bouton Expand/Collapse (si plus de 2 événements) */}
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Typography sx={{ fontWeight: '900' }} variant="h6"> {events.length}</Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <Typography variant="body1"> {date}</Typography>
                      <Box sx={{ flexGrow: 1 }} />
  
                      {showExpandButton && (
  
                        <ExpandMore
                          sx={{ mr: 1 }}
                          expand={openSections[date]}
                          onClick={(e) => toggleSection(date)}
                          aria-expanded={openSections[date]}
                          aria-label='Voir plus'
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      )}
                      {!showExpandButton && <Box sx={{ width: '40px', pr: 1 }} />}
                    </Box>
  
                    {/* Liste des événements */}
                    {events.slice(0, 2).map((event, index) => (
                      <Box
                        key={event.id || index}
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleSelectEvent(event)}
                      >
                        <MyCalendarEventMemo event={event} view={view} isDraggable={true} dragStart={dragStart} garage={garage} />
                      </Box>
                    ))}
  
                    {/* Collapse pour les événements supplémentaires */}
                    {showExpandButton && (
                      <Collapse in={isExpanded} timeout='auto' unmountOnExit>
                        {events.slice(2).map((event, index) => (
                          <Box
                            key={event.id || index + 2}
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleSelectEvent(event)}
                          >
                            <MyCalendarEventMemo event={event} view={view} isDraggable={true} dragStart={dragStart} garage={garage}/>
                          </Box>
                        ))}
                      </Collapse>
                    )}
                  </Box>
                );
              })}
  
  
  
            {view === Views.DAY && (sortEventsAsc(filteredEvents).map((event, index) => (
              <Box sx={{ cursor: 'pointer' }} onClick={() => handleSelectEvent(event)}>
                <MyCalendarEventMemo event={event} view={view} isDraggable={true} dragStart={dragStart} garage={garage}/>
              </Box>
            )))}
            {filteredEvents.length === 0 && (
              <>
                <Typography
                  variant='body2'
                  color={theme.palette.text.secondary}
                  sx={{ textAlign: 'center', fontFamily: 'CarterOne', textWrap: 'pretty' }}
                >
                  {inputSearch.length !== 0 ? (
                    <p>
                      Aucun rendez-vous trouvé
                      <br />
                    </p>
                  ) : (
                    <p>Aucun rendez-vous à affecter</p>
                  )}
                </Typography>
                {inputSearch.length !== 0 && <Typography
                  variant='body2'
                  color={theme.palette.text.secondary}
                  sx={{ textAlign: 'center', textWrap: 'pretty' }}
                >
                  Vous pouvez essayer avec :<br />
                  Le nom du client
                  <br />
                  Le prénom du client
                  <br />
                  Le n° de téléphone
                  <br />
                  L'immatriculation
                  <br />
                  Le modèle du véhicule
                </Typography>}
              </>
            )}
          </CardContent>
        </Card>
      );
    };
  }