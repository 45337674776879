import { Event, EventState, EventType, type Garage } from '@movalib/movalib-commons';
import { findEarliestScheduleTime } from '../DateUtils';
import moment from 'moment-timezone';

const daysOfWeek = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

type AddLunchTimeUnavailabilityProps = {
  events: Event[];
  garageId: Garage['id'];
  schedules: Garage['schedules'];
  currentDay: Date;
  garageTimeZone: string;
};

/**
 * Crée une Date représentant une heure LOCALE (dans timezone du garage),
 * qui s'affichera correctement dans le calendrier.
 */
function createLocalDate(baseDate: Date, timeString: string, timeZone: string): Date {
  const [hour, minute] = timeString.split(':').map(Number);

  const localMoment = moment.tz({
    year: baseDate.getFullYear(),
    month: baseDate.getMonth(),
    day: baseDate.getDate(),
    hour,
    minute,
    second: 0,
    millisecond: 0,
  }, timeZone);

  return localMoment.toDate();
}
function toTimeString(value: string | Date): string {
  return typeof value === 'string' ? value : moment(value).format('HH:mm:ss');
}
export function addLunchTimeUnavailability({
  events,
  schedules,
  garageId,
  currentDay,
  garageTimeZone,
}: AddLunchTimeUnavailabilityProps): Event[] {
  if (!schedules.length) return events;

  const eventsWithUnavailabilities = [...events];

  const earliestStartTime = findEarliestScheduleTime(schedules, 'startTime');
  const earliestEndTime = findEarliestScheduleTime(schedules, 'endTime');

  schedules.forEach((schedule) => {
    const dayOfWeekIndex = daysOfWeek[schedule.dayOfWeek];

    const baseDate = new Date(currentDay);
    baseDate.setDate(baseDate.getDate() + ((7 + dayOfWeekIndex - baseDate.getDay()) % 7));

    let dayStart = createLocalDate(baseDate, earliestStartTime, garageTimeZone);
    const dayEnd = createLocalDate(baseDate, earliestEndTime, garageTimeZone);

    const validIntervals = schedule.intervals.filter((i) => i.startTime && i.endTime);
    const sortedIntervals = validIntervals.sort((a, b) =>
      String(a.startTime).localeCompare(String(b.startTime))
    );

    sortedIntervals.forEach((interval) => {
      const intervalStart = createLocalDate(baseDate, toTimeString(interval.startTime!), garageTimeZone);
      const intervalEnd = createLocalDate(baseDate, toTimeString(interval.endTime!), garageTimeZone);

      if (intervalStart > dayStart) {
        eventsWithUnavailabilities.push(
          new Event(
            '',
            Number(garageId),
            EventType.UNAVAILABILITY,
            'Fermé',
            '',
            Number(garageId),
            '',
            EventState.CANCELLED,
            undefined,
            dayStart,
            intervalStart,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            false
          )
        );
      }

      dayStart = intervalEnd;
    });

    if (dayStart < dayEnd) {
      eventsWithUnavailabilities.push(
        new Event(
          '',
          Number(garageId),
          EventType.UNAVAILABILITY,
          'Fermé',
          '',
          Number(garageId),
          '',
          EventState.CANCELLED,
          undefined,
          dayStart,
          dayEnd,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          false
        )
      );
    }
  });

  return eventsWithUnavailabilities;
}
