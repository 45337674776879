import type React from 'react';
import { Box, FormControl, FormHelperText } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { base64ToUtf8, isValidBase64, modulesQuill, utf8ToBase64 } from '../helpers/Tools';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
interface CustomTextareaProps {
  value: string;
  onChange: (e: string) => void;
  onBlur?: () => void;
  error?: string | undefined;
  placeholder?: string;
  helperText?: React.ReactNode;
  id?: string;
  name?: string;
}
const CustomTextarea: React.FC<CustomTextareaProps> = ({
  value,
  onChange,
  onBlur,
  error,
  placeholder = 'Notes internes',
  helperText,
  id,
  name,
}) => {
  const quillRef = useRef<ReactQuill | null>(null);

  const [showToolbar, setShowToolbar] = useState(false);

  useEffect(() => {
    const quillDiv = quillRef?.current?.getEditor().root as HTMLDivElement;
    if (quillDiv) {
      quillDiv.style.height = 'auto'; // Réinitialise la hauteur
      quillDiv.style.height = `${quillDiv.scrollHeight + 5}px`
      quillDiv.style.minHeight = '75px';
      quillDiv.style.fontFamily = 'Outfit, sans-serif';
    }
  }, [quillRef.current?.value]);


  const handleInput = (value: string) => {
    
    onChange(utf8ToBase64(value)); // Appelle la fonction onChange
  };

  



  return (
    <FormControl fullWidth error={Boolean(error)}>
      <ReactQuill theme="snow" ref={quillRef}
        id={id}
        className={showToolbar ? 'show-toolbar' : 'hide-toolbar'}
        placeholder={placeholder}
        style={{
          width: '100%',
          fontSize: '1rem',
          fontFamily: 'Outfit, sans-serif',
          padding: '8px',
          border: 'none',
          outline: 'none',
          resize: 'none',
          overflow: 'hidden', 
        }}
        modules={modulesQuill}
        value={base64ToUtf8(value)}
        onFocus={() => setShowToolbar(true)} 
        onChange={handleInput}
        onBlur={() => {onBlur?.()}} 
       />

      <FormHelperText>{error ? error : helperText}</FormHelperText>
    </FormControl>
  );
};
export default CustomTextarea;
