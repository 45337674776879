import { type FunctionComponent, useRef, useState } from 'react';
import { Grid, Typography, Button, TableContainer, Card, Table, TableHead, TableRow, TableBody, TableCell, IconButton } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { GarageService, type Supplier, type Garage } from '@movalib/movalib-commons';
import { StyledTableCell } from '../../../helpers/Styled';
import theme from '../../../theme';
import { setSnackbar } from '../../../slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import AddOrEditSupplierDialog from '../../../dialogs/AddOrEditSupplierDialog';
import EditIcon from '@mui/icons-material/Edit';

interface SuppliersTableProps {
    garage: Garage;
    refreshGarage: () => void;
}

const SuppliersTable: FunctionComponent<SuppliersTableProps> = ({ garage, refreshGarage }) => {
    const dispatch = useDispatch();
    const [openAddOrEditSupplier, setOpenAddOrEditSupplier] = useState(false);
    const modifySupplier = useRef();
    const handleDeleteSupplier = (supplierId: number) => {
        if (garage?.id && supplierId) {
            // Suppression du grossiste
            GarageService.deleteGarageSupplier(garage.id, String(supplierId)).then((response) => {
                if (response.success) {
                    dispatch(setSnackbar({ open: true, message: response.data ?? 'Fournisseur supprimé', severity: 'success' }));
                    refreshGarage();
                } else {
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: response.error ?? 'Suppression du fournisseur impossible',
                            severity: 'error',
                        }),
                    );
                }
            });
        }
    };

    const handleOpenAddSupplier = () => {
        setOpenAddOrEditSupplier(true);
    };

    const handleCloseAddSupplier = (refresh: boolean) => {
        setOpenAddOrEditSupplier(false);
        modifySupplier.current = undefined;
        // On refresh les data si demandée
        if (refresh) {
            refreshGarage();
        }
    };
    const handleEditSupplier = (supplier: any) => {
        modifySupplier.current = supplier;
        setOpenAddOrEditSupplier(true);

     };
    return (
        <>
            <Grid item xs={12}>
                <Typography variant='h6' color={theme.palette.text.secondary}>
                    <b>Fournisseurs</b>
                </Typography>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Button
                        onClick={handleOpenAddSupplier}
                        color='primary'
                        size='small'
                        variant='contained'
                        sx={{ mt: 1, mb: 1, textTransform: 'none', width: '125px' }}
                        startIcon={<AddIcon />}
                    >
                        Ajouter
                    </Button>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <TableContainer component={Card} sx={{ mt: 2 }}>
                        <Table stickyHeader size='small' aria-label='suppliers table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Nom</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell>N° client</StyledTableCell>
                                    <StyledTableCell>N° téléphone</StyledTableCell>
                                    <StyledTableCell>Site web</StyledTableCell>
                                    <StyledTableCell>Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {garage?.suppliers && garage.suppliers.length === 0 ? (
                                    <TableRow key='aucun-result'>
                                        <TableCell colSpan={5} align='center' sx={{ color: theme.palette.text.secondary, p: 4 }}>
                                            AUCUN RESULTAT
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    garage?.suppliers?.sort((a: Supplier, b: Supplier) => a.id - b.id).map((supplier) => (
                                        <TableRow key={`${supplier.id}-supplier`} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                            <TableCell size='small'>{supplier.name}</TableCell>
                                            <TableCell>
                                                <b>{`${supplier.email}`}</b>
                                            </TableCell>
                                            <TableCell size='small'>{supplier.customerId}</TableCell>
                                            <TableCell size='small'>{supplier.phoneNumber}</TableCell>
                                            <TableCell size='small'>{supplier.orderPortalUrl}</TableCell>
                                            <TableCell align='right'>
                                                <IconButton
                                                    aria-label='modifier fournisseur'
                                                    size='small'
                                                    onClick={() => handleEditSupplier(supplier)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label='supprimer fournisseur'
                                                    size='small'
                                                    onClick={() => handleDeleteSupplier(supplier.id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {openAddOrEditSupplier && garage && garage.id && (
                <AddOrEditSupplierDialog
                    supplier={modifySupplier.current}
                    open={openAddOrEditSupplier}
                    garageId={garage.id}
                    onClose={handleCloseAddSupplier}
                    showHelp={true}
                />
            )}
        </>
    );
};
export default SuppliersTable;
