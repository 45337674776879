import {
  ConfirmationDialog,
  Garage,
  GarageService,
  Logger,
  MovaDialog,
  MovaFormField,
  Operation,
  OrderPreference,
  PartsApplicationType,
  Prestation,
  Product,
  ProductType,
  validateEmail,
  validateField,
} from '@movalib/movalib-commons';
import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState, type FC, FormEvent, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../slices/snackbarSlice';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { flexCenterRow, flexEnd, flexStart, importIcon } from '../helpers/Tools';
import { Palette } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/AddRounded';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import SetupIcon from '@mui/icons-material/HourglassBottom';
import { PALETTE_THIRD_COLOR_LIGHT, PALETTE_THIRD_COLOR_MAIN } from '../helpers/Constants';
import { useBoolState } from '../helpers/hooks/useBoolState';
import { add, set } from 'lodash';

interface AddPrestationDialogProps {
  open: boolean;
  garage: Garage;
  showHelp?: boolean;
  onClose?: (refresh: boolean) => void;
}

type PrestationForm = {
  name: MovaFormField;
  downtime: MovaFormField;
  appointmentDelay: MovaFormField;
  operationsList: MovaFormField;
  productList: MovaFormField;
  category: MovaFormField;
};

const initialFormState = {
  name: { value: '', error: '', isValid: true },
  downtime: { value: 0, error: '', isValid: true },
  appointmentDelay: { value: 0, error: '', isValid: true },
  operationsList: { value: [], error: '', isValid: true },
  productList: { value: [], error: '', isValid: true },
  category: { value: '', error: '', isValid: true },
};

const AddPrestationDialog: FC<AddPrestationDialogProps> = ({ open, garage, showHelp = false, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [form, setForm] = useState<PrestationForm>(initialFormState);
  const dispatch = useDispatch();

  const prestationsInactives = garage.prestations.filter((prestation) => !prestation.active);
  const [prestationsInactivesFiltered, setPrestationsInactivesFiltered] = useState<Prestation[]>([]);
  const { isConfirmDialogOpened, toggleConfirmDialogOpened } = useBoolState(false, 'confirmDialogOpened');
  const currentPrestationActive = useRef<Prestation | null>(null);

  const operationFields = useRef<string>('');
  const [fullOperations, setFullOperations] = useState<Operation[]>([]);
  const [filteredOperations, setFilteredOperations] = useState<Operation[]>([]);

  const productFields = useRef<string>('');
  const [fullProduct, setFullProduct] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

  useEffect(() => {
    GarageService.getAllOperations(garage.id).then((response) => {
      setFullOperations(response.data as unknown as Operation[]);
    });
    GarageService.getAllProducts(garage.id).then((response) => {
      setFullProduct(response.data as unknown as Product[]);
    });
  }, [garage]);

  const purgeLocalState = () => {
    setForm(initialFormState);
  };

  const handleOnClose = (refresh: boolean) => {
    purgeLocalState();
    if (onClose) {
      onClose(refresh);
    }
  };

  const validateForm = () => {
    const newForm: PrestationForm = { ...form };

    // Validator pour les champs obligatoires
    newForm.name = validateField(form.name, (value) => !!value, 'Champ obligatoire');
    newForm.downtime = validateField(form.downtime, (value) => !!value && value !== '', 'Champ obligatoire');
    newForm.appointmentDelay = validateField(
      form.appointmentDelay,
      (value) => value === '0' || (!!value && value !== ''),
      'Champ obligatoire',
    );
    newForm.category = validateField(form.category, (value) => !!value, 'Champ obligatoire');

    setForm(newForm);

    const isValid = Boolean(
      newForm.name.isValid &&
        newForm.downtime.isValid &&
        newForm.operationsList.isValid &&
        newForm.category.isValid &&
        newForm.productList.isValid,
    );

    return isValid;
  };

  const isNotValid = () => {
    return Boolean(
      form.name.value.length === 0 ||
        form.downtime.value.length === 0 ||
        form.category.value === '',
    );
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      if (validateForm() && garage) {
        // Requête de création d'une prestation
        const newOperationList = form.operationsList.value.map((operation: Operation) => {
          if (operation.id === -1) {
            return { name: operation.name };
          }
          return { code: operation.code };
        });
        const newProductList = form.productList.value.map((product: Product) => {
          if (product.id === '-1') {
            return { name: product.name };
          }
          return { code: product.code };
        });
        let req = {
          name: form.name.value,
          downtime: form.downtime.value,
          appointmentDelay: form.appointmentDelay.value,
          operations: newOperationList,
          products: newProductList,
          categoryCode: form.category.value,
        };

        GarageService.createGaragePrestationRequest(garage.id, req).then((response) => {
          if (response.success) {
            dispatch(
              setSnackbar({
                open: true,
                message: response.data ?? "La demande d'ajout de prestation a été envoyée avec succès",
                severity: 'success',
              }),
            );
            // On ferme la dialog box avec demande de refresh
            handleOnClose(true);
          } else {
            dispatch(
              setSnackbar({
                open: true,
                message: response.error ?? 'Envoi de la demande impossible',
                severity: 'error',
              }),
            );
          }
        });
      }
    } catch (error) {
      Logger.error('Error occurred during submission:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const fieldName: string = e.target.name;
    const fieldValue: string = e.target.value;
    if (fieldName === 'name') {
      handleFilteredByString(prestationsInactives, fieldValue, setPrestationsInactivesFiltered);
    }
    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

    setForm({ ...form, ...newField });
  };

  const handleFilteredByString = (array: any[], filter: string, setArray: (e: any[]) => void) => {
    if (filter.length > 2) {
      setArray(
        array.filter((item) =>
          item.name
            .normalize('NFD') // Décompose les caractères accentués
            .replace(/[\u0300-\u036f]/g, '') // Supprime les diacritiques
            .toLowerCase()
            .includes(
              filter
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase(),
            ),
        ),
      );
    } else {
      setArray([]);
    }
  };

  const addItem = (item: Operation | Product, name: 'productList' | 'operationsList') => {
    setForm((prevForm) => ({
      ...prevForm,
      [name]: {
        ...prevForm[name],
        value: [...prevForm[name].value, item],
        isValid: true,
      },
    }));
    if (name === 'operationsList') {
      operationFields.current = '';
    } else {
      productFields.current = '';
    }
  };
  const handleDeleteItem = (index: number, name: 'productList' | 'operationsList') => {
    setForm((prevForm) => ({
      ...prevForm,
      [name]: {
        ...prevForm[name],
        value: prevForm[name].value.filter((_: Product, i: number) => i !== index),
        isValid: true,
      },
    }));
  };
  const addNewOperation = () => {
    const newOperation = new Operation(-1, '', operationFields.current, '', PartsApplicationType.FRONT, []);
    addItem(newOperation, 'operationsList');
  };

  useEffect(() => {
    handleFilteredByString(fullOperations, operationFields.current, setFilteredOperations);
  }, [form.operationsList.value]);

  const addNewProduct = () => {
    const newProduct = new Product(
      '-1',
      '',
      productFields.current,
      ProductType.CONSUMABLE,
      OrderPreference.HIGH_RANGE,
      [],
    );
    addItem(newProduct, 'productList');
  };

  useEffect(() => {
    handleFilteredByString(fullProduct, productFields.current, setFilteredProducts);
  }, [form.productList.value]);

  const askToActivePrestation = (prestation: Prestation) => {
    currentPrestationActive.current = prestation;
    toggleConfirmDialogOpened();
  };
  const activePrestation = (prestation: Prestation | null) => async () => {
    if (garage && prestation) {
      GarageService.enableGaragePrestation(garage.id, String(prestation.id)).then((response) => {
        if (response.success) {
          dispatch(setSnackbar({ open: true, message: response.data ?? 'Prestation activée', severity: 'success' }));
          handleOnClose(true);
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Activation de la prestation impossible',
              severity: 'error',
            }),
          );
        }
      });
    }
    // Requête de création d'une prestation
    //close dialog
  };

  return (
    <>
      <MovaDialog
        fullScreen={isMobile}
        open={open}
        onClose={() => handleOnClose(false)}
        leafImageColor='green'
        maxWidth='sm'
        closable
        title={"DEMANDE D'AJOUT D'UNE PRESTATION"}
        actions={
          <Button
            onClick={handleSubmit}
            color='primary'
            sx={{ width: '90%' }}
            disabled={isNotValid()}
            variant='contained'
          >
            Envoyer la demande
          </Button>
        }
      >
        <Grid container>
          {showHelp && (
            <Alert severity='success' sx={{ mb: 2 }} icon={<SetupIcon />} style={flexCenter} className='styled-alert'>
              <Typography style={flexCenter}>
                Votre nouvelle prestation sera disponible d'ici quelques jours. En cas d'urgence, pensez à utiliser la
                prestation "Autre..."
              </Typography>
            </Alert>
          )}

          <Grid item xs={12}>
            <TextField
              margin='normal'
              required
              autoFocus
              fullWidth
              id='name'
              label='Nom de la prestation'
              name='name'
              onChange={(e) => handleInputChange(e)}
              value={form.name.value}
              error={Boolean(form.name.error)}
              helperText={Boolean(form.name.error) ? form.name.error : 'Choisissez un nom assez court'}
            />
            {prestationsInactivesFiltered.length > 0 && (
              <List>
                <Typography variant='body1' color={theme.palette.primary.main} style={flexCenter}>
                  Prestation(s) existante(s)
                </Typography>
                {prestationsInactivesFiltered?.map((prestation, index) => (
                  <ListItemButton onClick={() => askToActivePrestation(prestation)} key={index}>
                    <ListItemIcon>
                      {prestation.code && importIcon(prestation.code) && (
                        <img
                          src={importIcon(prestation.code)}
                          style={{
                            width: '20px',
                            height: '20px',
                            paddingLeft: '4px',
                            marginRight: '7px',
                          }}
                          alt=''
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={prestation.name} />
                  </ListItemButton>
                ))}
              </List>
            )}
          </Grid>

          {/* operation section */}
          <Grid item xs={12} sx={{ mt: 2 }} style={flexStart}>
            <Typography variant='body1'>Ajouter vos opérations ({form.operationsList.value.length})</Typography>
          </Grid>
          {form.operationsList.value.length > 0 && (
            <Grid item xs={12} sx={{ mt: 2 }} style={{ ...flexStart, flexWrap: 'wrap', gap: 5 }}>
              {form.operationsList.value.map((operation: Operation, index: number) => (
                <Chip
                  sx={{ backgroundColor: theme.palette.primary.main }}
                  label={operation.name}
                  onDelete={() => handleDeleteItem(index, 'operationsList')}
                />
              ))}
            </Grid>
          )}

          <Grid item xs={12} sx={{ mt: 2 }} style={flexStart}>
            <TextField
              margin='none'
              fullWidth
              sx={{ mr: 2 }}
              id='operation'
              label="Nom de l'opération"
              name='operation'
              value={operationFields.current}
              onChange={(e) => {
                handleFilteredByString(fullOperations, e.target.value, setFilteredOperations);
                operationFields.current = e.target.value;
              }}
              helperText={'Ex: Remplacement filtre à huile'}
            />
            {operationFields.current.length > 2 && (
              <Button variant='contained' sx={{ px: 3 }} startIcon={<AddIcon />} onClick={() => addNewOperation()}>
                Ajouter
              </Button>
            )}
          </Grid>
          {filteredOperations.filter(
            (item) =>
              form.operationsList.value.findIndex((operation: Operation) => operation.name === item.name) === -1,
          ).length > 0 && (
            <List sx={{ width: '100%', maxHeight: '400px', overflowY: 'auto' }}>
              <Typography variant='body1' color={theme.palette.primary.main} style={flexCenter}>
                Opération(s) existante(s)
              </Typography>
              {filteredOperations
                .filter(
                  (item) =>
                    form.operationsList.value.findIndex((operation: Operation) => operation.name === item.name) === -1,
                )
                .map((operation: Operation, index) => (
                  <ListItemButton onClick={() => addItem(operation, 'operationsList')} key={index}>
                    <ListItemText primary={operation.name} />
                  </ListItemButton>
                ))}
            </List>
          )}

          {/* product section */}
          <Grid item xs={12} sx={{ mt: 2 }} style={flexStart}>
            <Typography variant='body1'>
              Ajouter vos pièces et consommables ({form.productList.value.length})
            </Typography>
          </Grid>
          {form.productList.value.length > 0 && (
            <Grid item xs={12} sx={{ mt: 2 }} style={{ ...flexStart, flexWrap: 'wrap', gap: 5 }}>
              {form.productList.value.map((product: Product, index: number) => (
                <Chip
                  key={`${product.name + index}chip`}
                  sx={{ backgroundColor: theme.palette.primary.main }}
                  label={product.name}
                  onDelete={() => handleDeleteItem(index, 'productList')}
                />
              ))}
            </Grid>
          )}

          <Grid item xs={12} sx={{ mt: 2 }} style={flexStart}>
            <TextField
              margin='none'
              fullWidth
              sx={{ mr: 2 }}
              id='product'
              label='Nom de la pièce ou consommable'
              name='producy'
              value={productFields.current}
              onChange={(e) => {
                handleFilteredByString(fullProduct, e.target.value, setFilteredProducts);
                productFields.current = e.target.value;
              }}
              helperText={'Indiquez unqiuement le nom de la pièce, sans application (AR / AV / ARD...)'}
            />
            {productFields.current.length > 2 && (
              <Button variant='contained' sx={{ px: 3 }} startIcon={<AddIcon />} onClick={() => addNewProduct()}>
                Ajouter
              </Button>
            )}
          </Grid>
          {filteredProducts.filter(
            (item) => form.productList.value.findIndex((product: Product) => product.name === item.name) === -1,
          ).length > 0 && (
            <List sx={{ width: '100%', maxHeight: '400px', overflowY: 'auto' }}>
              <Typography variant='body1' color={theme.palette.primary.main} style={flexCenter}>
                Pièce(s) ou consommable(s) existant(s)
              </Typography>
              {filteredProducts
                .filter(
                  (item) => form.productList.value.findIndex((product: Product) => product.name === item.name) === -1,
                )
                .map((product: Product, index) => (
                  <ListItemButton onClick={() => addItem(product, 'productList')} key={product.name + index}>
                    <ListItemText primary={product.name} />
                  </ListItemButton>
                ))}
            </List>
          )}

          <Grid item xs={12} sx={{ mt: 2 }} style={flexStart}>
            <Typography variant='body1'>Choisissez la catégorie</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }} style={flexStart}>
            <FormControl fullWidth>
              <InputLabel id='label-select-category-presta'>Catégorie de la prestation *</InputLabel>
              <Select
                labelId='label-select-category-presta'
                id='select-category'
                required
                value={form.category.value}
                label='Catégorie de la prestation *'
                onChange={(e) => setForm({ ...form, category: { value: e.target.value, isValid: true } })}
              >
                {garage.prestationCategories.map((category, index) => (
                  <MenuItem key={category.name + index} value={category.code}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {Boolean(form.category.error)
                  ? form.category.error
                  : "Regroupe les prestations courantes d'entretien, les révisions ou les niveaux"}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }} style={flexStart}>
            <Typography variant='body1'>Durée courante d'immobilisation véhicule :</Typography>
          </Grid>
          <Grid item xs={5} sx={{ mt: 2 }}>
            <Box style={flexStart}>
              <TextField
                id='downtime'
                value={form.downtime.value}
                type='number'
                required
                size='small'
                name='downtime'
                onChange={(e) => {
                  handleInputChange(e);
                }}
                error={!form.downtime.isValid}
                sx={{ width: '100px', mr: 2 }}
                InputProps={{ inputProps: { min: 0 } }}
              />{' '}
              <Typography variant='body2'> Heure(s)</Typography>{' '}
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }} style={flexStart}>
            <Typography variant='body1'>Délai de prévenance avant pris de rendez-vous :</Typography>
          </Grid>
          <Grid item xs={5} sx={{ mt: 2 }}>
            <Box style={flexStart}>
              <TextField
                id='appointmentDelay'
                value={form.appointmentDelay.value}
                type='number'
                required
                size='small'
                name='appointmentDelay'
                onChange={(e) => {
                  // Convertir la valeur en entier, en ignorant les entrées non numériques
                  const value = e.target.value;
                  const intValue = value === '' ? '' : Number.parseInt(value, 10);
                  // Utiliser une RegExp pour vérifier si la valeur est un entier valide
                  if (/^\d*$/.test(value)) {
                    // Cette expression régulière accepte des nombres entiers, y compris "0"
                    // Créez ici un nouvel objet event ou ajustez directement la valeur avant de passer à handleInputChange
                    e.target.value = intValue.toString();
                    handleInputChange(e);
                  }
                }}
                error={!form.appointmentDelay.isValid}
                sx={{ width: '100px', mr: 2 }}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />{' '}
              <Typography variant='body2' sx={{ mr: 1 }}>
                {' '}
                Jour(s)
              </Typography>{' '}
            </Box>
          </Grid>
        </Grid>
      </MovaDialog>
      <ConfirmationDialog
        open={isConfirmDialogOpened && currentPrestationActive.current !== null}
        onClose={toggleConfirmDialogOpened}
        onConfirm={activePrestation(currentPrestationActive.current)}
        title='Activer cette prestations ?'
        confirmLabel='Confirmer'
        closeLabel='Annuler'
        message={
          <ListItem>
            <ListItemIcon>
              {currentPrestationActive.current?.code && importIcon(currentPrestationActive.current?.code!) && (
                <img
                  src={importIcon(currentPrestationActive.current?.code!)}
                  style={{
                    width: '20px',
                    height: '20px',
                    paddingLeft: '4px',
                    marginRight: '7px',
                  }}
                  alt=''
                />
              )}
            </ListItemIcon>
            <ListItemText primary={currentPrestationActive.current?.name} />
          </ListItem>
        }
        maxWidth='sm'
        fullWidth
      />
    </>
  );
};

export default AddPrestationDialog;
