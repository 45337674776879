import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
  Typography,
  Input,
  useTheme,
  createTheme,
  ThemeProvider,
  Card,
  CardContent,
  Chip,
  Avatar,
  TextField,
  Alert,
  Toolbar,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
} from '@mui/material';
import type React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'; // Import the AutoAwesomeIcon component
import { type Garage, DocumentType, type Document } from '@movalib/movalib-commons';
import { flexCenter, getApplicationsShortLabels } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import AppointmentsIcon from '@mui/icons-material/TodayRounded';
import { alpha, darken } from '@mui/material/styles';
import { base64ToUtf8, importIcon, isValidBase64, utf8ToBase64 } from '../../../helpers/Tools';
import SmsIcon from '@mui/icons-material/Sms';
import ConfirmIcon from '@mui/icons-material/Check';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CancelIcon from '@mui/icons-material/CloseRounded';
import BackIcon from '@mui/icons-material/ArrowBackIosNewRounded';

interface CustomStyleAppDialogProps {
  open: boolean;
  onClose: () => void;
  garage: Garage;
  saveSetting: (r: any) => void;
}
const useDebouncedValue = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

const CustomStyleAppDialog: React.FC<CustomStyleAppDialogProps> = ({ open, onClose, garage, saveSetting }) => {
  const prestation = garage.prestations[2];
  const theme = useTheme();
  const customStyle = JSON.parse(garage.customStyle || '{}');
  const [color, setColor] = useState(customStyle?.color ?? theme.palette.primary.main);
  const [additionalDescription, setAdditionalDescription] = useState<string>(customStyle?.additionalDescription ?? '');
  const debouncedColor = useDebouncedValue(color, 300); // 300ms de debounce
  const [garageLogo, setGarageLogo] = useState<Document | undefined>(
    garage.documents?.find((doc) => doc.type === DocumentType.GARAGE_LOGO),
  );
  const quillRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    const quillDiv = quillRef?.current?.getEditor().root as HTMLDivElement;
    if (quillDiv) {
      quillDiv.style.minHeight = '75px';
      quillDiv.style.fontFamily = 'Outfit, sans-serif';
    }
  }, [quillRef.current?.value]);
  const customTheme = useMemo(
    () =>
      createTheme({
        ...theme,
        palette: {
          ...theme.palette,
          primary: {
            main: debouncedColor,
          },
        },
      }),
    [debouncedColor, theme],
  );
  function hexToHSL(hex: string) {
    // Convert HEX to RGB
    const r = Number.parseInt(hex.slice(1, 3), 16) / 255;
    const g = Number.parseInt(hex.slice(3, 5), 16) / 255;
    const b = Number.parseInt(hex.slice(5, 7), 16) / 255;

    // Find min and max values to get luminance
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h: number;
    let s: number;
    let l: number;
    h = s = l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h = Math.round((h as number) * 60); // Convert to degrees
    }

    return { h, s: s * 100, l: l * 100 };
  }

  function calculateHueRotation(hexFrom: string, hexTo: string) {
    const fromHSL = hexToHSL(hexFrom);
    const toHSL = hexToHSL(hexTo);

    let rotation = toHSL.h - fromHSL.h;
    if (rotation < 0) {
      rotation += 360; // Ensure positive rotation
    }

    return rotation;
  }

  const submitAndClose = () => {
    const query = {
      customStyle: {
        color: debouncedColor,
        additionalDescription: additionalDescription,
      },
    };
    saveSetting({ customStyle: JSON.stringify(query.customStyle) });
    onClose();
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }],
      ['bold', 'italic', 'underline', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['clean'],
    ],
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='lg'
      scroll='body'
      PaperProps={{ sx: { my: 0 } }}
      sx={{ p: 0 }}
    >
      <Toolbar
        disableGutters
        variant='dense'
        sx={{
          display: 'block',
          minHeight: 3,
          backgroundColor: theme.palette.grey[200],
          p: 0,
        }}
      >
        <DialogTitle>
          <AutoAwesomeIcon style={{ marginRight: '16px', color: color }} />
          Personnalisation de Movalib - Rendez-vous en ligne
          <Typography variant='body2' sx={{ mt: 1 }}>
            Personnalisez le style du parcours de prise de rendez-vous en ligne depuis Movalib.
          </Typography>
        </DialogTitle>
      </Toolbar>
      <DialogContent
        sx={{
          p: 0,
          maxHeight: 'calc(var(--fullHeight) - 68px - 54px - 64px)',
          position: 'relative',
          overflowX: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flex: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: '35%' }}>
            <Typography variant='h6'>Couleur principale</Typography>
            <Typography variant='body2'>Cliquez ci-dessous pour la modifier</Typography>
            <Input
              type='color'
              value={color}
              onChange={(e) => setColor(e.target.value)}
              sx={{ width: '100' }}
              inputProps={{
                sx: { cursor: 'pointer' },
              }}
            />

            <Typography variant='h6'>Description additionnelle</Typography>
            <Typography variant='body2'>
              Ce texte personnalisé apparaîtra sur le récapitulatif lors d'une demande de rendez-vous en ligne.
            </Typography>
            <ReactQuill
              ref={quillRef}
              theme='snow'
              modules={modules}
              value={base64ToUtf8(additionalDescription ?? '')}
              onChange={(v) => {
                if (v === '<p><br></p>' || v === '<p> </p>') v = '';
                setAdditionalDescription(utf8ToBase64(v));
              }}
            />
            {!garageLogo && (
              <Alert className='styled-info-alert' color='info' sx={{ mt: 5 }}>
                Pensez à ajouter votre Logo, il apparaîtra dans l'en-tête de l'application Movalib 😉
              </Alert>
            )}
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2, width: '600px' }}>
            <Typography style={flexCenter} sx={{ fontSize: '1rem' }} color='text.secondary'>
              Exemple de rendu sur Movalib (confirmation du rdv) ✨
            </Typography>

            <ThemeProvider theme={customTheme}>
              <Box
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  display: 'flex',
                  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.15)' /* Légère ombre */,
                  borderRadius: '8px' /* Coins arrondis pour adoucir l'effet */,
                  position: 'relative',
                  border: `1px solid ${customTheme.palette.primary.main}`,
                }}
              >
                <Grid container sx={{ mt: 1 }}>
                  <Grid item xs={2} style={flexCenter} sx={{ pl: 1 }}>
                    <IconButton edge='start' color='inherit' aria-label='close'>
                      <BackIcon />
                    </IconButton>
                  </Grid>

                  <Grid item xs={8} style={flexCenter}>
                    <Typography
                      sx={{ textAlign: 'center', fontSize: 20, flexGrow: 1 }}
                      style={{
                        color: customTheme.palette.primary.dark,
                        fontWeight: 700,
                        height: '30px',
                      }}
                    >
                      {garage.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={flexCenter}>
                    {garageLogo && (
                      <img
                        src={garageLogo.fileSignedUrl}
                        alt='Logo du Garage'
                        style={{ maxHeight: '70px', right: 0, top: '0px' }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Typography
                  variant='h6'
                  component='div'
                  color={customTheme.palette.text.primary}
                  style={flexCenter}
                  sx={{ mt: 2, mb: 3, textDecoration: 'underline' }}
                >
                  Votre demande de rendez-vous
                </Typography>
                <FormControlLabel control={<Checkbox />} label='Demander un véhicule de prêt si disponible' />
                <Card
                  variant='outlined'
                  sx={{
                    backgroundColor: alpha(customTheme.palette.primary.light, 0.7),
                    overflow: 'visible',
                    m: 2,
                    mb: 3,
                    width: '90%',
                  }}
                >
                  <CardContent
                    sx={{
                      pt: 2,
                      pb: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <AppointmentsIcon sx={{ mb: 1 }} />
                    {/** Rappel du créneau */}
                    <Typography
                      variant='subtitle1'
                      component='div'
                      align='center'
                      sx={{ mb: 2, color: customTheme.palette.primary.contrastText }}
                      color='text.primary'
                    >
                      <b>Le Mardi 28 Janvier à 14:00</b>
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      component='div'
                      align='center'
                      sx={{ mb: 1, color: customTheme.palette.primary.contrastText }}
                      color='text.primary'
                    >
                      <b>RENAULT CLIO 4 1.5 DCI - 8V TURBO</b>
                    </Typography>
                    {/** Rappel des prestations sélectionnées */}
                    <Chip
                      key={prestation.id}
                      style={{
                        backgroundColor: customTheme?.palette.primary.light,
                        color: customTheme?.palette.primary.contrastText,
                        margin: '2px',
                      }}
                      label={<Typography>{prestation.name}</Typography>}
                      avatar={
                        importIcon(prestation.code) ? (
                          <Avatar
                            src={importIcon(prestation.code)}
                            style={{
                              width: '25px',
                              height: '25px',
                              filter: `hue-rotate(${calculateHueRotation('#a0bd39', customTheme.palette.primary.main)}deg)`,
                              background: 'white',
                            }}
                            alt=''
                          />
                        ) : undefined
                      }
                    />
                  </CardContent>
                </Card>

                <TextField
                  sx={{ width: '95%', mb: 2 }}
                  id='note'
                  label='Commentaire pour le garagiste'
                  multiline
                  rows={4}
                />

                {/* <Alert
                                    severity="success"
                                    icon={<SmsIcon style={{ color: customTheme.palette.primary.dark }} />}
                                    sx={{
                                        borderRadius: 20,
                                        backgroundColor: alpha(customTheme.palette.primary.light, 0.5),
                                        color: customTheme.palette.primary.contrastText, // Couleur du textex
                                    }}
                                >
                                    Vous recevrez un SMS de confirmation une fois votre demande traitée
                                    😉
                                </Alert> */}
                {additionalDescription && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: 2,
                      width: '100%',
                    }}
                  >
                    {additionalDescription && (
                      <Box sx={{ flex: '100%' }}>
                        <div
                          className='ql-editor'
                          style={{
                            fontFamily: 'Outfit, sans-serif',
                            minWidth: '100%',
                            width: '100%',
                            overflow: 'hidden',
                            textWrap: 'wrap',
                            wordBreak: 'break-word',
                          }}
                          dangerouslySetInnerHTML={{ __html: base64ToUtf8(additionalDescription) }}
                        />
                      </Box>
                    )}
                  </Box>
                )}

                <Button sx={{ width: '90%', my: 2 }} variant='contained'>
                  <ConfirmIcon sx={{ mr: 1 }} />
                  Valider ma demande
                </Button>
              </Box>
            </ThemeProvider>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ mb: 1 }}>
        <Button variant='contained' onClick={onClose} color='inherit' sx={{ minWidth: '150px' }}>
          <CancelIcon sx={{ mr: 1 }} />
          Annuler
        </Button>
        <Button variant='contained' onClick={submitAndClose} color='primary'>
          <SaveOutlined sx={{ mr: 1 }} />
          Sauvegarder
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomStyleAppDialog;
