import { type Garage, MovaAppType, MovaDialog, QrCodePLVContainer, User, UserService } from '@movalib/movalib-commons';
import ToolsIcon from '@mui/icons-material/BuildRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  type SxProps,
  type Theme,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { lighten } from '@mui/material';
import React, { type FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MOVALIB_APP_BASE_URL } from '../helpers/Constants';
import { AppBarPagesEnum, AppBarSettingsEnum, PrivateRoutesEnum } from '../helpers/Enums';
import Logger from '../helpers/Logger';
import { deleteCookies, findValueByKey, purgeGlobalSate } from '../helpers/Tools';
import { setSnackbar } from '../slices/snackbarSlice';
import { ContactBtnMenu } from './ContactBtnMenu';
import AdbIcon from '@mui/icons-material/Adb';
import type { RootState } from '../store';
import { useQueryClient } from '@tanstack/react-query';
import { reset, setGarageData } from '../slices/garageSlice';

const Logo = require('../assets/images/logo/header_logo.png');
const QRCodeIcon = require('../assets/images/qrcode_small.png');
const MyBusinessLogo = require('../assets/images/google_my_business_logo.jpg');

type MyAppBarProps = {
  garage: Garage;
  activeMenuItem?: string;
  sx?: SxProps<Theme>; // Ajout de la propriété sx
};

const MyAppBar: FunctionComponent<MyAppBarProps> = ({ garage, activeMenuItem, sx }) => {
  // Fetch les infos du garage dynamiquement selon la demande

  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [openQRCodeDialog, setOpenQRCodeDialog] = useState<boolean>();
  const isMd = useMediaQuery(theme.breakpoints.down('md')); // Vérifie si l'écran est inférieur à 'md'
  const [user, setUser] = useState<User | null>(useSelector((state: RootState) => state.user.user));
  const queryClient = useQueryClient();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handlePageClick = (page: string) => {
    //Fermeture du menu
    handleCloseUserMenu();

    //Redirection vers la page demandée
    const requestedPage = findValueByKey(PrivateRoutesEnum, page);

    if (requestedPage) {
      // Cas particulier du Logout
      if (page === 'LOGOUT') {
        reset();
        dispatch(setGarageData(null));
        queryClient.clear();
        // Suppression des cookies
        deleteCookies();
        // Suppression des données persistées en localStorage
        purgeGlobalSate();
        // Redirection vers le login
        history.push('/login');
      } else {
        history.push(requestedPage);
      }
    }
  };

  const handleOpenQRCodeDialog = () => {
    setOpenQRCodeDialog(true);
  };

  const getGarageLink = (): string => {
    Logger.info(MOVALIB_APP_BASE_URL);
    // On renvoie les données pour le QR Code, l'url change selon l'environnement (variables d'environnement)
    return `${MOVALIB_APP_BASE_URL}/#/garage/${garage.id}`;
  };

  const getQRCodeData = (): string => {
    Logger.info(MOVALIB_APP_BASE_URL);
    // On renvoie les données pour le QR Code, l'url change selon l'environnement (variables d'environnement)
    return `${MOVALIB_APP_BASE_URL}/#/garage/${garage.id}?redirect=garage`;
  };

  const copyQRCodeToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(setSnackbar({ open: true, message: 'Lien copié !', severity: 'success' }));
    } catch (err) {
      Logger.error('Erreur lors de la copie dans le presse-papier :', err);
    }
  };

  return (
    <>
      <AppBar position='fixed' sx={{ backgroundColor: lighten(theme.palette.background.paper, 0.6), ...sx }}>
        <Container maxWidth={false}>
          <Toolbar disableGutters variant='dense'>
            {user && User.isCsM(user) && <IconButton onClick={(e) => { e.stopPropagation(); history.push('csm-list') }}
              sx={{
                position: 'absolute', 
                color: theme.palette.secondary.main, 
                backgroundColor: 'black', '&:hover': {
                  backgroundColor: "gray",
                }, 
                zIndex: 200
              }}>
              <AdbIcon />
            </IconButton>
            }
            <IconButton
              edge='start'
              color='inherit'
              aria-label='logo'
              disableRipple
              onClick={() => handlePageClick('AGENDA')}
              sx={{
                mr: 2,
                display: 'flex',
                ':hover': {
                  bgcolor: 'transparent',
                },
              }}
            >
              <img src={Logo} alt='Logo Movalib' style={{ width: isMd ? 'auto' : '80%', height: isMd ? '37px' : 'auto' }} />
            </IconButton>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />
            <Box sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
              <ContactBtnMenu garage={garage} />
            </Box>
            <Box sx={{ flexGrow: 2, display: { xs: 'none', md: 'flex' }, justifyContent: 'end', mr: 2 }}>
              {Object.entries(AppBarPagesEnum)
                // Filtrer l'affichage de l'élément 'TEAM' en fonction de l'attribut `garage?.teamManagementActive`
                .filter(([key, value]) => {
                  if (key === 'TEAM') {
                    return garage?.teamManagementActive;
                  }
                  return true;
                })
                .map(([key, value]) => (
                  <Button
                    size='medium'
                    key={key}
                    onClick={() => handlePageClick(key)}
                    sx={{
                      my: 2,
                      mx: 2,
                      color: 'text.primary',
                      display: 'block',
                      backgroundColor:
                        activeMenuItem && activeMenuItem.toUpperCase() === key.toUpperCase()
                          ? 'text.light'
                          : 'transparent',
                      ':hover': {
                        bgcolor: 'text.light',
                      },
                    }}
                  >
                    {value}
                  </Button>
                ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title='Afficher votre QR Code'>
                <IconButton sx={{ p: 0, borderRadius: 0, mr: 2, display: { xs: 'none', md: 'inline-flex' } }} onClick={handleOpenQRCodeDialog}>
                  <Avatar alt='Image QR Code' src={QRCodeIcon} sx={{ borderRadius: 0 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Ouvrir le menu'>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                  <ToolsIcon />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {Object.entries(AppBarSettingsEnum).filter(([key]) => !(key === 'SETTINGS' && isMd)).map(([key, value]) => (
                  <MenuItem key={key} onClick={() => handlePageClick(key)}>
                    <Typography textAlign='center'>{value}</Typography>
                  </MenuItem>
                ))}
                <MenuItem sx={{ display: { xs: 'flex', md: 'none' } }}><ContactBtnMenu garage={garage} /></MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {openQRCodeDialog && (
        <MovaDialog
          title='Voici votre QR Code'
          open={openQRCodeDialog}
          fullScreen={false}
          onClose={() => setOpenQRCodeDialog(false)}
        >
          <Typography variant='body1' sx={{ mb: 3, lineHeight: 1.5 }}>
            Il redirige vos clients vers votre page de prise de rendez-vous.
            <br />
            <b>Imprimez la PLV</b> ou téléchargez le QR Code et facilitez vous la vie !
          </Typography>

          <QrCodePLVContainer data={getQRCodeData()} />

          <Grid
            container
            style={{ border: '1px solid #F29ABA', borderRadius: '10px', display: 'flex', alignItems: 'center' }}
            sx={{ mt: 2 }}
          >
            <Grid item xs={4}>
              <a href='https://www.google.com/intl/fr_fr/business/' target='_blank' rel='noreferrer noopener'>
                <img src={MyBusinessLogo} alt='Logo Google MyBusiness' style={{ width: '150px' }} />
              </a>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='body1'>
                Copiez le lien ci-dessous et partager-le sur vos réseaux et votre fiche <b>Google MyBusiness</b> 😉
              </Typography>
            </Grid>
          </Grid>
          <Typography variant='body1' sx={{ mt: 2, lineHeight: 2 }}>
            <a target='_blank' rel='noreferrer noopener' href={getGarageLink()}>
              {getGarageLink()}
            </a>
            <Button
              variant='text'
              size='small'
              color='inherit'
              onClick={() => copyQRCodeToClipboard(getGarageLink())}
              sx={{ ml: 3 }}
            >
              <ContentCopyIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
              Copier
            </Button>
          </Typography>
        </MovaDialog>
      )}
    </>
  );
};

export default MyAppBar;
