import { formatDateByTimezone, DateFormatTypes, type Event, Garage } from '@movalib/movalib-commons';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import theme from '../../../theme';
import { Grid, Alert, Typography, Box } from '@mui/material';
import DateTimePickerGroup from '../../DateTimePickerGroup';
import EventScheduleIcon from '@mui/icons-material/AccessTimeFilledRounded';
import type { MutableRefObject } from 'react';


type ModifyDropOffVehicleProps = {
    event: Event | undefined;
    movedDepositDate: MutableRefObject<Date | undefined>;
    garage: Garage;
  };

export const ModifyDropOffVehicle = ({event, movedDepositDate, garage}: ModifyDropOffVehicleProps) => {
    return (
        <Grid container direction='column' alignItems='center' gap={1}>
        <Alert
          sx={{ mb: 1, width: '90%', textAlign: 'center', fontSize: '1rem' }}
          severity='warning'
          style={flexCenter}
        >
          Vous avez déplacé le rendez-vous. <br />
          Souhaitez-vous modifier l’heure de dépôt du véhicule ?
        </Alert>
        <Typography variant='subtitle1'>
          <Typography variant='h6'>
            Heure de dépôt initiale :{' '}
            <b>
              {event?.vehicleDepositDate &&
                `${formatDateByTimezone(
                  new Date(event.vehicleDepositDate),
                  garage.timezone,
                  DateFormatTypes.LONG_FORMAT_DATETIME_LITERAL,
                )}`}
            </b>
          </Typography>
        </Typography>
        <Box>
          <Typography variant='h6'>Nouvel horaire de dépôt du véhicule</Typography>
        </Box>
        <Grid container sx={{ mt: 1, mb: 1 }}>
          <Grid item xs={1} style={flexCenter}>
            <EventScheduleIcon sx={{ color: theme.palette.grey[500] }} />
          </Grid>
          <Grid item xs={11}>
            <DateTimePickerGroup
              dateLabel='Dépôt du véhicule'
              timeLabel='Heure'
              value={movedDepositDate.current}
              onChange={(newValue) => {
                if (newValue) {
                  movedDepositDate.current = newValue;
                }
              }}
              sx={{ justifyContent: 'center', width: '100%', pl: 1 }}
            />
          </Grid>
        </Grid>
      </Grid>
)};
